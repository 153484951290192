<script>
import {mapActions} from 'vuex';

export default {
  name: "onlineUsers",
  data: () => ({
    dialog : false
  }),
  methods: {
    ...mapActions(['retrieveOnlineUsers']),
    refreshUsers: function () {
      this.retrieveOnlineUsers();
    },
    closeWin: function() {
      this.dialog = false ;
    }
  },
  mounted() {
  },
  computed: {
    ouserlist() {
      return this.$store.state.onlineusers;
    },
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '80vw';
      } else if (this.$vuetify.display.name==='md') {
        return '60vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '50vw';
      } else {
        return '50vw';
      }
    },
  }
}
</script>

<template>
  <v-dialog
      v-model="dialog"
      :width="dlgwid"
      persistent
      theme="light"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          class="text-right"
          size="small"
          v-bind="props"
          variant="outlined"
      > Online Users
      </v-btn>
    </template>

    <v-card
        color="white"
        min-width="500"
        variant="flat"
    >
      <v-card-title>
        Online Users
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-table density="compact">
              <thead>
              <tr>
                <th class="text-left">
                  User
                </th>
                <th class="text-left">
                  County/Municipality
                </th>
                <th class="text-left">
                  Last Seen Online
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="ou in ouserlist">
                <td>{{ ou['FirstName'] }} {{ ou['LastName'] }}</td>
                <td>{{ ou['Name'] }} ({{ ou['County'] }},{{ ou['State'] }})</td>
                <td>{{ ou['LastOp'] }}</td>
              </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            density="default"
            variant="outlined"
            @click="refreshUsers"
        >Refresh
        </v-btn>
        <v-btn
            density="default"
            variant="outlined"
            @click="closeWin"
        >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>