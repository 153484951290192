<script>
import {saveData} from "@/services.js";
export  default {
  methods: {
    setEditUserId() {
      this.users.forEach(u => {
        if (u.userid === this.uid) {
          this.firstName = u.FirstName;
          this.lastName = u.LastName;
          this.mobilePhone = u.mobile;
          this.homePhone = u.phone;
          this.email = u.email;
          this.uroles = u.uroles;
        }
      })
    },
    initVars() {
      this.firstName = '';
      this.lastName = '';
      this.mobilePhone = '';
      this.homePhone = '';
      this.email = '';
      this.uroles = '';
    },
    async saveUser() {
      const fd = new FormData();
      fd.append('userid', this.uid);
      fd.append('FirstName',this.firstName);
      fd.append('LastName', this.lastName);
      fd.append('phone', this.homePhone);
      fd.append('mobile', this.mobilePhone);
      fd.append('uroles', this.uroles);
      if (this.uid>0) {
        fd.append('mode', 'saveuser');
      } else {
        fd.append('mode', 'adduser');
        fd.append('email', this.email);
      }
      let result1 = await saveData(fd);
      if (!!result1['Status']) {
        if (result1['Status']==='OK') {
          this.dialog = false;
        }
      }
    }
  },
  computed: {
    dtitle() {
      return this.uid<0?'Add New User':`Edit User ${this.firstName} ${this.lastName}`;
    },
    users() {
      return this.$store.state.users;
    },
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '70vw';
      } else if (this.$vuetify.display.name==='md') {
        return '50vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '30vw';
      } else {
        return '30vw';
      }
    },
    not_enough_data() {
      let flag = (this.mobilePhone !== '' || this.homePhone !== '' || this.isExistingUser) ;
      flag = flag && (this.firstName !== '') && (this.lastName !== '') && (this.uroles !== '');
      console.log(JSON.stringify(this.uroles));
      return !flag ;
    },
    isExistingUser() {
      return this.uid>0
    }
  },
  props: {
    uid: Number
  },
  data() {
    return {
      message1:'',
      message2:'',
      message3:'',
      message4:'',
      dialog: false,
      firstName: '',
      lastName: '',
      email: '',
      homePhone: '',
      mobilePhone: '',
      uroles: '',
      rolelist: [
        {code:'0', value:'ESF-1 (Transportation)'},
        {code:'1', value:'ESF-2 (Communications)'},
        {code:'18', value:'ESF-2a911 (Communications+911)'},
        {code:'2', value:'ESF-3 (Public Works/Engineering)'},
        {code:'3', value:'ESF-4 (Firefighting)'},
        {code:'4', value:'ESF-5 (Emergency Management)'},
        {code:'5', value:'ESF-6 (Mass Care/Human Services)'},
        {code:'6', value:'ESF-7 (Logistics/Resource Support)' },
        {code:'7', value:'ESF-8 (Public Health/Medical Services)'},
        {code:'8', value:'ESF-9 (Search and Rescue)'},
        {code:'9', value:'ESF-10 (Hazardous Materials)'},
        {code:'10', value:'ESF-11 (Agriculture/Natural Resources)'},
        {code:'11', value:'ESF-12 (Energy)'},
        {code:'12', value:'ESF-13 (Public Safety/Law Enforcement'},
        {code:'13', value:'ESF-14 (Long Term Community Recovery)'},
        {code:'14', value:'ESF-15 (External Affairs/PIO)'},
        {code:'15', value:'Municipal/Local'},
        {code:'16', value:'Administrator'},
        {code:'17', value:'Empty/No Permissions'},
      ]
    }
  }
}
</script>
<template>
      <v-dialog
          v-model="dialog"
          persistent
          :width="dlgwid"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              v-if="uid>0"
              variant="flat"
              icon = "mdi-account-edit"
              density="compact"
              v-bind="props"
              @click="setEditUserId"
          >
          </v-btn>
          <v-btn
              v-if="uid<0"
              class="text-right"
              v-bind="props"
              variant="outlined"
              @click="initVars"
          > Create new user
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center mt-3">{{dtitle}}</v-card-title>
          <v-card-text>
              <v-row no-gutters>
                <v-col
                    cols="6"
                    class="pa-0 pr-2"
                >
                  <v-text-field
                      variant="outlined"
                      density="compact"
                      label="First name"
                      v-model="firstName"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    class="pa-0 pl-2"
                >
                  <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Last name"
                      v-model="lastName"
                  ></v-text-field>
                </v-col>
              </v-row>
            <v-row no-gutters>
                <v-col
                    class="pa-0"
                    cols="12">
                  <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Email"
                      v-model="email"
                      required
                      :readonly="isExistingUser"
                  ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col
                    class="pa-0 pr-2"
                    cols="6">
                  <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Home phone number"
                      type="Home phone number"
                      v-model="homePhone"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    class="pa-0 pl-2"
                    cols="6">
                  <v-text-field
                      density="compact"
                      variant="outlined"
                      label="Mobile phone number"
                      type="Mobile phone number"
                      v-model="mobilePhone"
                      required
                  ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col
                    class="pa-0"
                    cols="12">
                  <v-autocomplete
                      density="compact"
                      variant="outlined"
                      :items="rolelist"
                      item-value="code"
                      item-title="value"
                      label="Role"
                      v-model="uroles"
                  ></v-autocomplete>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
                class="ml-1 mb-1"
                variant="outlined"
                @click="dialog = false"
                width="30%"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="mr-1 mb-1"
                variant="outlined"
                @click="saveUser"
                width="30%"
                :disabled="not_enough_data"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>
<style>
</style>