<template>
  <div class="notes">
    <h3>Notes</h3>
    <div class="notes-list">
      <table class="tab">
        <tbody>
        <tr v-for="entry in notesData">
          <td class="td1">
            <button class="imgbutton" @click="setEdit(entry.id)"><img alt="edit" src="/assets/modify12.png"/></button>
            <button class="imgbutton" @click="confirmDeleteNote(entry.id, entry.note)">
              <img alt="edit" src="/assets/delete12.png"/>
            </button>
          </td>
          <td class="td0">
            <div class="td00"></div>
          </td>
          <td class="td2">
            <span>{{ entry.note }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <ConfirmDialogue ref="confirmNote"/>
    </div>
    <div class="notes-edit">
      <input v-model="newNote.id" type="hidden"/>
      <input v-model="newNote.note" placeholder="Note text" type="text"/>
      <button @click="addNote()">Save</button>
      <button @click="reset()">Clear</button>
    </div>
  </div>
</template>

<script>
import {objectToFormData, save911} from "@/services.js";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

export default {
  name: "notes911",
  components: {ConfirmDialogue},
  data() {
    return {
      newNote: {
        id: -1,
        note: ''
      }
    }
  },
  computed: {
    notesData() {
      return this.$store.state._911_notesData;
    }
  },
  methods: {
    async confirmDeleteNote(noteid, notetxt) {
      try {
        const result = await this.$refs.confirmNote.open({
          title: 'Confirm Note Deletion',
          message: `Note <strong>${notetxt}</strong> will be deleted`
        });
        if (result) {
          let xdata = {mode: 'del', id: noteid};
          await save911(objectToFormData(xdata))
              .then(response => {
                if (response.Status === 'OK') {
                  this.$store.commit('delete_911_notes', noteid);
                }
              });
        }
      } catch (err) {
      }
    },
    reset: function () {
      this.newNote.id = -1;
      this.newNote.note = "";
    },
    setEdit: function (keyid) {
      const idx = this.notesData.findIndex(note => note.id === keyid);
      if (idx === -1) alert("Problem");
      else {
        this.newNote.id = this.notesData[idx].id;
        this.newNote.note = this.notesData[idx].note;
      }
    },
    async addNote() {
      if (this.newNote.note === '') {
        alert('Note cannot be empty');
      } else {
        let xdata = {mode: 'save', id: this.newNote.id, notetext: this.newNote.note};
        await save911(objectToFormData(xdata))
            .then(response => {
              if (response.Status === 'OK') {
                this.$store.commit('update_911_notes', {'id': parseInt(response.id), note: this.newNote.note});
                this.newNote.id = -1;
                this.newNote.note = '';
              }
            });
      }
    }
  },
}
</script>

<style scoped>
.tab {
  width: 100%;
}

.td0 {
  width: 30px;
}

.td00 {
  display: inline-block;
  background-image: url('/assets/notes24.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 28px;
  height: 28px;
}

.td1 {
  padding-left: 10px;
  width: 60px;
  box-sizing: border-box;
}

.td2 {
  font-size: 16px;
  padding: 5px 5px 5px 15px;
  font-style: italic;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.notes {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: 'Calibri', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

.notes h3 {
  height: 25px;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
}

.notes-list {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.notes-edit {
  width: 100%;
  height: 35px;
  border: 1px solid #fe0000;
}

.notes-edit input {
  width: 55%;
  display: block;
  border: 1px solid #fe0000;
  margin: 5px 0 5px 5px;
  height: 23px;
  float: left;
}

.notes-edit button {
  width: 41px;
  display: block;
  margin: 5px 5px 5px 0;
  padding: 0;
  height: 23px;
  background-color: grey;
  outline: none;
  float: right;
}

.imgbutton {
  padding: 2px;
  line-height: 12px;
  border: 2px solid black;
}
</style>
