<script>
import {mapGetters} from "vuex";

export default {
  methods: {
    ...mapGetters(['getCenters']),
    getEditCenter: function () {
      const cenlist = this.getCenters();
      cenlist[this.ctype].forEach(cen => {
        if (cen.id === this.cid) {
          this.address = cen.address;
          this.descr = cen.descr;
          this.capacity = cen.capacity;
          this.occup = cen.occup;
          this.sstatus = cen.status;
          this.op_appr = cen.op_appr;
          this.genpwr= cen.genpwr;
          this.longterm= cen.longterm;
          this.daytime= cen.daytime;
          this.kitchen=cen.kitchen;
          this.shower=cen.shower;
          this.pets=cen.pets;
        }
      })
    },
    updateCenter: function () {
      let cenObj = {
        'id': this.cid,
        'address': this.address,
        'descr': this.descr,
        'capacity': this.capacity,
        'occup': this.occup,
        'sstatus': this.sstatus,
        'op_appr': this.op_appr,
        'genpwr': this.genpwr,
        'longterm': this.longterm,
        'daytime': this.daytime,
        'kitchen': this.kitchen,
        'shower': this.shower,
        'pets': this.pets
      } ;
      if (this.ctype==='shelters') {
        cenObj['mode']='updshelt'
        cenObj['cfunc']=this.cfunc;
      }
      if (this.ctype==='decons') {
        cenObj['mode']='upddecrec'
      }
      if (this.ctype==='recepts') {
        cenObj['mode']='upddecrec'
      }
      this.dialog = false;
      // TODO send to backend
    },
  },
  props: {
    cid: Number,
    ctype: String
  },
  data: () => ({
    dialog: false,
    typedefs: {
      'shelters': 'Shelter',
      'decons': 'Decon Center',
      'recepts': 'Reception Center'
    },
    sstat: [{v: 0, l: "Not Set"}, {v: 1, l: "Closed"}, {v: 2, l: "Open"}],
    address: null,
    descr: null,
    capacity: null,
    occup: null,
    sstatus: null,
    op_appr: null,
    genpwr: null,
    longterm: null,
    daytime:null,
    kitchen:null,
    shower:null,
    pets:null,
//    "op_appr":false,"genpwr":false,"longterm":false,"daytime":false,"kitchen":false,"shower":false,"pets":false
  }),
  computed: {
    cfunc() {
      return `${this.op_appr?'1':'0'}${this.genpwr?'1':'0'}${this.longterm?'1':'0'}${this.daytime?'1':'0'}`+
          `${this.kitchen?'1':'0'}${this.shower?'1':'0'}${this.pets?'1':'0'}` ;
    }
  }
}
</script>

<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        class="sh_dlg_w"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            density="compact"
            icon="mdi-pen"
            v-bind="props"
            variant="flat"
            @click="getEditCenter"
        ></v-btn>
      </template>
      <v-card>
        <v-card-title class="text-center"> Edit {{ typedefs[ctype] }}</v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-text-field
                  v-model="descr"
                  density="compact"
                  label="Shelter description"
                  readonly
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-text-field
                  v-model="address"
                  density="compact"
                  label="Shelter Address"
                  readonly
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="ctype==='shelters'">
            <v-col
                class="pa-0 mt-1"
                cols="12"
            >
              <span class="text-h7 mb-4 ml-4 font-weight-bold">Shelter information </span>
            </v-col>
          </v-row>
          <v-row v-if="ctype==='shelters'">
            <v-col cols="6" sm="6" md="4" lg="4" class="pa-0">
              <v-checkbox
                  density="compact"
                  v-model="op_appr"
              >
                <template v-slot:label>
                  <div class="text-body-2">Opening Approved</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3" class="pa-0">
                <v-checkbox
                    density="compact"
                    v-model="genpwr"
                >
                  <template v-slot:label>
                    <div class="text-body-2">Genset Power</div>
                  </template>
                </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="5" class="pa-0">
                <v-checkbox
                    density="compact"
                    v-model="longterm"
                >
                  <template v-slot:label>
                    <div class="text-body-2">Long term</div>
                  </template>
                </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4" class="pa-0">
                <v-checkbox
                    density="compact"
                    v-model="daytime"
                >
                  <template v-slot:label>
                    <div class="text-body-2">Daytime</div>
                  </template>
                </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3" class="pa-0">
                <v-checkbox
                    density="compact"
                    label="Kitchen"
                    v-model="kitchen"
                ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="4" md="3" lg="3" class="pa-0">
                <v-checkbox
                    density="compact"
                    label="Showers"
                    v-model="shower"
                ></v-checkbox>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" class="pa-0">
            <v-checkbox
                    density="compact"
                    label="Pets"
                    v-model="pets"
                ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-autocomplete
                  v-model="sstatus"
                  :items="sstat"
                  density="compact"
                  item-title="l"
                  item-value="v"
                  label="Status"
                  variant="outlined"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col
                class="pa-0 pr-1"
                cols="6"
            >
              <v-text-field
                  v-model="capacity"
                  density="compact"
                  label="Capacity"
                  required
                  variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col
                class="pa-0 pl-1"
                cols="6"
            >
              <v-text-field
                  v-model="occup"
                  density="compact"
                  label="Occupancy"
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
              class="mb-5 ml-3"
              size="default"
              variant="outlined"
              width="47%"
              @click="dialog = false"
          > Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="mb-5 mr-3"
              size="default"
              variant="outlined"
              width="47%"
              @click="updateCenter"
          > Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
@media only screen and (max-width: 600px) {
  .sh_dlg_w {
    width: 450px; /* Adjust the max-width as needed */
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  /* Medium screen */
  .sh_dlg_w {
    width: 450px; /* Adjust the max-width as needed */
  }
}

@media only screen and (min-width: 961px) {
  /* Large screen */
  .sh_dlg_w {
    width: 580px; /* Adjust the max-width as needed */
  }
}</style>