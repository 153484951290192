<template>
    <v-menu
        offset-y
        bottom
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, props }">
        <v-btn
            v-bind="props"
        >
          <v-avatar size="32">
            <v-img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="User"></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="goToProfile">
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logOut">
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    goToProfile() {
      // this.$router.push('/profile');
      // this.menu = false;
    },
    logOut() {
      // Implement your log out logic here
      // this.menu = false;
    },
  },
};
</script>

<style scoped>
.v-avatar {
  cursor: pointer;
}
</style>