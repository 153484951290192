<script>
export default {
  name: "resrequest",
  data: () => ({}),
  methods: {
    zoomTo: function (oid) {
      readymap.setCenter(new google.maps.LatLng(this.resreqDataA[oid].Lat,this.resreqDataA[oid].Lng));
      readymap.setZoom(16);
    },
    zoomAll:function(){
      readymap.fitBounds(userprof.bnd);
    }
  },
  computed: {
    resreqData() {
      return this.$store.state._edb_resreqs;
    }
  }
}
</script>
<template>
    <div class="resreq">
        <h3><a href='#' @click="zoomAll()">RESOURCE REQUESTS</a></h3>
        <div class="resreq-list">
            <table class="tab">
                <tbody>
                <tr class="hdrtab">
                    <th>Jurisdiction</th>
                    <th>Severity</th>
                    <th>Resource Type</th>
                    <th>Order #</th>
                    <th>Description</th>
                </tr>
                <tr v-for="entry in resreqData">
                    <td class="td3">
                        <span>{{entry.jname}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.severitytext}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.rneedtxt}}</span>
                    </td>
                    <td class="td3">
                        <span><a href="#" @click="zoomTo(entry.oid)" class="rrrow">{{entry.idx}}</a></span>
                    </td>
                    <td class="td2">
                        <span>{{entry.rneedescr}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style scoped>
    .tab{
        width: 100%;
    }
    .hdrtab {
        background-color:#7c93c0
    }
    h3 {
        text-align: left;
        padding-left:10px;
    }
    .td2 {
        font-size: 14px;
        padding : 5px 5px 5px 15px;
        font-weight: bold;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .td3 {
        font-size: 14px;
        padding : 5px 5px 5px 15px;
        font-style: normal;
        font-weight: bold;
        text-align: left;
    }
    .resreq {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .resreq h3 {
        height: 25px;
    }
    .resreq-list {
        width:100%;
        flex: 1;
        overflow-y: auto;
    }
</style>
