<script>
export default {
  methods: {
    goToHome: function () {
      let self = this;
      self.$router.push({name: "home"})
    }
  },
  data: () => ({
    dialog: false,
  }),
}
</script>
<template>
  <v-row >
    <v-dialog
        v-model="dialog"
        persistent
        width="40vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            prepend-icon="mdi-plus"
            v-bind="props"
            density="default"
            variant="outlined"
            width="100%"
        > Create new Event </v-btn>
        <v-spacer></v-spacer>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">Create New Event </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    variant="outlined"
                    label="Event name"
                    density="compact"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    variant="outlined"
                    label="Event address"
                    append-inner-icon="mdi-magnify"
                    density="compact"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-0"
                  cols="12">
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['General', 'HazMat', 'Radiological', 'Explosives']"
                    label="Event type"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['Pre-planning','Open','Open for Analysis','Closed','Archived']"
                    label="Event Status"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['Salem county','All Municipalities','EPZ towns','Atlantic County','Cumberland County','Gloucester county','Alloway Township', 'Carneys Point Township']"
                    label="Affected Jurisdiction"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0 pr-2"
                  cols="6"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['Yes', 'No']"
                    label="Public reports"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0 pl-2"
                  cols="6"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['Yes', 'No']"
                    label="Surveyor reports"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              @click="dialog = false"
              width="30%"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              @click="dialog=false"
              width="30%"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style>
</style>