<template>
    <div class="incident">
        <h3><a href='#' @click="zoomAll()">INCIDENTS</a></h3>
        <div class="incident-list">
            <table class="tab">
                <tbody>
                <tr class="hdrtab">
                    <th>Jurisdiction</th>
                    <th>Incident ID</th>
                    <th>Incident Type</th>
                    <th>Status</th>
                </tr>
                <tr v-for="entry in incidentList">
                    <td class="td3">
                        <span>{{entry.township}}</span>
                    </td>
                    <td class="td3">
                        <span><a href="#" @click="zoomTo(entry.oid)" class="incrow">{{entry.iid}}</a></span>
                    </td>
                    <td class="td2">
                        <span>{{entry.itypename}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.statustext}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "incident",
        data: () => ({}),
        methods: {
            zoomTo:function(oid) {
                // readymap.setCenter(new google.maps.LatLng(this.incidentList[oid].lat,this.incidentList[oid].lng));
                // readymap.setZoom(16);
            },
            zoomAll:function(){
                // readymap.fitBounds(userprof.bnd);
            }
        },
        computed: {
          incidentList() {
            return this.$store.state._edb_incidents;
          }
        }
    }
</script>

<style scoped>
    .tab{
        width: 100%;
    }
    .hdrtab {
        background-color:#7c93c0
    }
    h3 {
        padding-left:10px;
        text-align: left;
    }
    .td2 {
        font-size: 14px;
        padding : 5px 5px 5px 15px;
        font-weight: bold;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .td3 {
        font-size: 14px;
        padding : 5px 5px 5px 15px;
        font-style: normal;
        font-weight: bold;
        text-align: left;
    }
    .incident {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .incident h3 {
        height: 25px;
    }
    .incident-list {
        width:100%;
        flex: 1;
        overflow-y: auto;
    }
</style>
