<script>
import { objectToFormData, saveData} from "@/services.js";
export default {
  methods: {
    closeWin() {
      this.dialog = false;
    },
    async closeSave() {
      if (this.rsText!=='') {
        this.dialog = false;
        let payload = objectToFormData({id:this.eid,rtext:this.rsText,mode:'resreqrespond'});
        let result1 = await saveData(payload);
        if (!!result1['Status']) {
          if (result1['Status']==='OK') {
            this.rsTextErr = '';
            this.rsText = '';
            this.closeWin();
          }
        }
      } else {
        this.rsTextErr = 'Message should not be empty'
      }
    },
    setResReqId: function () {
      this.resReqId = this.eid;
      this.$store.state.incomingrrs.forEach(irr => {
        if (irr['id'] === this.resReqId) {
          this.selectedResReq = irr;
          console.log(JSON.stringify(this.selectedResReq));
        }
      })
    }
  },
  props: {
    mode: String,
    eid: Number,
    idx: String,
  },
  data: () => ({
    dialog: false,
    resReqId: null,
    selectedResReq: null,
    rsName : '',
    rsEmail : '',
    rsText: '',
    rsTextErr: '',
  }),
  computed: {
    dlgwid() {
      console.log(this.$vuetify.display.name);
      if (this.$vuetify.display.name === 'xs') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'sm') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'md') {
        return '75vw';
      } else if (this.$vuetify.display.name === 'lg') {
        return '53vw';
      } else {
        return '45vw';
      }
    }
  },
  mounted() {
    let userObj = {...this.$store.state.loggedUserObj};
    console.log(JSON.stringify(userObj));
    this.rsName = userObj.FIRST_NAME + ' ' + userObj.LAST_NAME;
    this.rsEmail = userObj.MEMBER_MAIL;
  }
}
</script>
<template>
  <v-dialog
      id="ResourceRequestResponse"
      v-model="dialog"
      :width="dlgwid"
      persistent
      theme="light"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          v-if="mode==='map'"
          density="comfortable"
          v-bind="props"
          variant="outlined"
      >Reply
      </v-btn>
      <a
          v-if="mode==='db'"
          class="text-blue-grey-darken-4"
          v-bind="props"
          @click="setResReqId">
        {{ idx }}
      </a>
    </template>
    <v-card>
      <v-card-title class="text-center mt-3"> Respond to Resource Request {{ this.idx }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list
                  class="ma-0 pa-0"
                  density="compact"
                  nav
              >
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Severity:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['severitytext'] }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Date Needed:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq.from }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Return date:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq.to }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Submitted by:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['usr'] }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Jurisdiction:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['jname'] }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Last Update:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['lastupd'] }}</v-chip>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list
                  class="ma-0 pa-0"
                  density="compact"
                  nav
              >
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">Resource Type Needed:</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['rneedtxt'] }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                    <v-list-item-title class="mr-2">{{ $needLabels[this.selectedResReq['rneedv']] }}</v-list-item-title>
                    <v-chip label small>{{ this.selectedResReq['need1'] ? this.selectedResReq['need1'] : 'N/A' }}</v-chip>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                        <span class="custom-title">
                          <span class="v-list-item-title v-list-item--nav pa-0 ma-0 mr-2">Situation Brief:</span>
                          <span class="v-list-item-subtitle pa-0 ma-0 mr-2">
                            {{ this.selectedResReq['sitbrief'] ? this.selectedResReq['sitbrief'] : 'N/A' }}
                          </span>
                        </span>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                        <span class="custom-title">
                          <span
                              class="v-list-item-title v-list-item--nav pa-0 ma-0 mr-2">Resource Need Description:</span>
                          <span class="v-list-item-subtitle pa-0 ma-0 mr-2 custom-subtitle">
                            {{ this.selectedResReq['rneedescr'] ? this.selectedResReq['rneedescr'] : 'N/A' }}
                          </span>
                        </span>
                  </div>
                </v-list-item>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                        <span class="custom-title">
                          <span class="v-list-item-title v-list-item--nav pa-0 ma-0 mr-2">Additional need:</span>
                          <span class="v-list-item-subtitle pa-0 ma-0 mr-2 custom-subtitle">
                            {{ this.selectedResReq['need2'] ? this.selectedResReq['need2'] : 'N/A' }}
                          </span>
                        </span>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-list
                  class="ma-0 pa-0"
                  density="compact"
                  nav
              >
                <v-list-item class="ma-0 pa-0 mr-1">
                  <div class="d-flex align-center ma-0 pa-0">
                        <span class="custom-title">
                          <span class="v-list-item-title v-list-item--nav pa-0 ma-0 mr-2">Delivery logistics:</span>
                          <span
                              class="v-list-item-subtitle pa-0 ma-0 mr-2 custom-subtitle">{{
                              this.selectedResReq['log_addr']
                            }}</span>
                        </span>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list
                  class="ma-0 pa-0"
                  density="compact"
                  nav
              >
                <v-list-item class="ma-0 pa-0 ml-1">
                  <div class="d-flex align-center ma-0 pa-0">
                        <span class="custom-title">
                          <span class="v-list-item-title v-list-item--nav pa-0 ma-0 mr-2">Point of Contact:</span>
                          <span
                              class="v-list-item-subtitle pa-0 ma-0 mr-2 custom-subtitle">{{
                              this.selectedResReq['log_name']
                            }}</span>
                        </span>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row
              class="text-h7 pa-0 font-weight-bold"> Respond to this resource request
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  label="User name"
                  variant="outlined"
                  v-model="rsName"
                  hide-details="true"
                  disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="User e-mail address"
                  variant="outlined"
                  v-model="rsEmail"
                  hide-details="true"
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  auto-grow
                  density="compact"
                  label="Message"
                  required
                  rows="2"
                  variant="outlined"
                  v-model="rsText"
                  :error-messages="rsTextErr"
                  class="custom-message-color"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            style="flex: 1"
            class="mb-2 mt-0 ml-8 mr-3"
            size="default"
            variant="outlined"
            @click="closeWin"
        >
          Close
        </v-btn>
        <v-btn
            style="flex: 1"
            class="mb-2 mt-0 ml-3 mr-8"
            size="default"
            variant="outlined"
            @click="closeSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.custom-title {
  text-overflow: unset !important;
  overflow: unset !important;
  display: inline !important;
}

.custom-subtitle {
  font-size: 0.8125rem !important;
  color: black !important;
}

.custom-message-color .v-messages__message {
  color: #ff5722; /* Your desired text color */
}
</style>