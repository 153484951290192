<script setup>
</script>
<template>
  <v-layout class="c100">
    <div id="wlayout">
      <mapwrk/>
    </div>
  </v-layout>
</template>
<script>
import mapwrk from "@/components/mapwrk.vue";
import "mapbox-gl/dist/mapbox-gl.css"

export default {
  components: {
    mapwrk
  },
  name: 'workersmap',
  props: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
  }
}
</script>

<style scoped>
#wlayout {
  flex: 1;
  display: flex;
  position: relative;
  width: 100vw;
  /*height: calc(100vh - 64px - 56px);*/
  margin-bottom: 0;
}
.c100 {
  height: 300px;
}
</style>