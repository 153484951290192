<template>
    <div class="incidents">
        <h3><a href="#" @click="zoomAll">Active Incidents</a></h3>
        <div class="incidents-list">
            <ul>
                <li class="td2" v-for="entry in incidents">
                    <div class="det-top">
                        <a href='#' @click="zoomTo(entry.idn)">{{entry.idn}}</a>,{{entry.itime}},{{entry.itype}}
                    </div>
                    <div class="details">
                        <div :class="entry.icontype">&nbsp;</div><div v-if="entry.iold>120" class="checkmark"></div>
                        <p>{{entry.iaddr}},<span class="resource">{{entry.iresrc}}</span></p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="incidents-footer">
            <a href="/911history.php" class="hist" target="_blank">History</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'incidents911',
  methods : {
    zoomAll:function(){
      iicepmap.setCenter(new google.maps.LatLng(39.63505, -74.744818));
      iicepmap.setZoom(9);
    },
    zoomTo:function(iId) {
      for (let i=0 ; i<markers.length ; i++) {
        if (markers[i].data.idn===iId){
          let pnt=markers[i].getPosition();
          iicepmap.setCenter(pnt);
          iicepmap.setZoom(17);
        }
      }
    }
  },
  computed: {
    incidents() {
      return this.$store.state._911_incidentData;
  }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.incidents {
    display: flex;
    height: 100%;
    flex-direction: column;
    font: 12px 'Calibri', Cambria, Georgia, "Times New Roman", serif;
}
.incidents h3 {
  height: 25px;
  font-size: 1.25rem;
  text-align: center;
}
.det-top {
    display: block;
}
ul {
  list-style-type: square;
  padding: 0;
}
a {
  color: #011efe;
}
.incidents-list {
    overflow-y: auto;
    text-align: left;
    width:100%;
    flex: 1;
}
.incidents-footer {
    width: 100%;
    height: 35px;
    text-align: right;
    padding: 5px;
}
.details {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: auto;
}
.details p {
    margin-top: 5px;
}
.resource {
    font-style: italic;
    text-decoration: underline;
}
.hist {
    display: inline-block;
    padding-right:5px;
    font-size: 14px;
    font-style: italic;
}
.ems {
    display: inline-block;
    background-image: url('/assets/ems24.png');
    background-repeat: no-repeat;
    background-position: center;
    width:28px;
    height:28px;
    float: left;
    margin-top:-2px;
}
.fire {
    display: inline-block;
    background-image: url('/assets/fire24.png');
    background-repeat: no-repeat;
    background-position: center;
    width:28px;
    height:28px;
    float: left;
    margin-top:-2px;
}
.fireems {
    display: inline-block;
    background-image: url('/assets/fireems24.png');
    background-repeat: no-repeat;
    background-position: center;
    width:28px;
    height:28px;
    float: left;
    margin-top:-2px;
}
.checkmark {
    display: inline-block;
    background-image: url('/assets/check24.png');
    background-repeat: no-repeat;
    background-position: center;
    width:28px;
    height:28px;
    float: left;
    margin-top:-2px;
}
.td2 {
    clear: both;
}
</style>
