<script>
import iicepStore from "@/iicepStore.js";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import {annoIcons, incIcons, annoColors} from '@/utils.js';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from '@/mapbox-gl-draw-circle';
import {mapState} from "vuex";
mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ'
export default {
  name: 'mapwin',
  data: () => ({
    map: null,
    draw: null,
    tempPin: null,
    resizeObserver: null,
    maplayers: [],
    incident_pins: [],
    event_pins: [],
    rr_pins: [],
    own_anno_pins: [],
    foreign_anno_pins: [],
    current_map: 'sat_map',
    // dmodes: {
    //   "simple_select": {'id':0,'lname':'','sname':'', 'ftrs': null},
    //   "draw_polygon": {'id':1,'lname':'own_polygons','sname':'own_polygons_src','ftrs':'own_polygon_ftrs'},
    //   "draw_line_string": {'id':2,'lname':'own_lines','sname':'own_lines_src','ftrs':'own_line_ftrs'},
    //   "draw_rectangle": {'id':3,'lname':'own_rects','sname':'own_rects_src','ftrs':'own_rect_ftrs'},
    //   "draw_circle": {'id':4,'lname':'own_circles','sname':'own_circles_src','ftrs':'own_circle_ftrs'},
    //   "draw_point": {'id':5,'lname':'own_points','sname':'own_points_src','ftrs':'own_point_ftrs'},
    // },
    sat_map:[
        'satellite'
    ],
    str_map:[
        'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
        'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
        'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
        'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
    ],
    sourcedefs: {
      anno_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      inc_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      ev_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      rr_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
    },
    layerdefs: [
       {
      id: 'anno_polygons',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_rects',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_circles',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_lines',
      type: 'line',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "LineString"],
      paint: {
        'line-color': ['get', 'clr'],
        'line-width': 2
      }
    }, {
      id: 'anno_points',
      type: 'symbol',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Point"],
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'inc_points',
      type: 'symbol',
      source: 'inc_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'ev_points',
      type: 'symbol',
      source: 'ev_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'rr_points',
      type: 'symbol',
      source: 'rr_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }
    ]
  }),
  computed: {
    ...mapState(['annoList','annoFtrSrc','mapmode','inc_ftrs','ev_ftrs','rr_ftrs', 'draw_mode', '_draw_edit'])
  },
  watch: {
    mapmode(newmode) {
      if (newmode==='str_map') {
         this.setStreetMap();
      }
      if (newmode==='sat_map') {
         this.setSatelliteMap();
      }
    },
    annoFtrSrc(newdata) {
      this.map.getSource('anno_ftrs_src').setData(newdata);
    },
    annoList(newdata) {
      this.reloadSource('anno_ftrs_src', newdata);
    },
    inc_ftrs(newdata) {
      this.reloadSource('inc_ftrs_src', newdata);
    },
    ev_ftrs(newdata) {
      this.reloadSource('ev_ftrs_src', newdata);
    },
    rr_ftrs(newdata) {
      this.reloadSource('rr_ftrs_src', newdata);
    },
    _draw_edit(newdata) {
      if (newdata) {
        this.load_anno_data();
      } else {
        this.unload_anno_data();
      }
    },
    draw_mode(new_mode,old_mode) {
      // 1-polygon
      // 2-polyline
      // 3-rectangle
      // 4-circle
      // 5-marker
      // console.log('Old',old_mode);
      // console.log('New',new_mode);
      // const old_dr_mode = this.dmodes[old_mode];
      // const new_dr_mode = this.dmodes[new_mode];
      // this.prepare_data(old_mode,new_mode);
      this.draw.changeMode(new_mode);

    }
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.map = map;
    map.on('load', () => {
      iicepStore.data.layers.forEach(lr => {
        let lstate = lr.state ? 'visible' : 'none';
        if (lr.type === 'layer') {
          map.setLayoutProperty(lr.id, 'visibility', lstate)
        }
        if (lr.type === 'group') {
          lr.members.forEach(mem => {
            map.setLayoutProperty(mem, 'visibility', lstate);
          })
        }
      });
      iicepStore.data.oem_init = true;
      Object.keys(this.sourcedefs).forEach(k => {
        map.addSource(k, this.sourcedefs[k]);
      });
      this.layerdefs.forEach(lr => {
        map.addLayer(lr);
      })
      this.addMarkerIcons().then(() => {
        this.reloadSource('anno_ftrs_src', this.annoFtrSrc);
        this.reloadSource('inc_ftrs_src', this.inc_ftrs);
        this.createEvents('inc_points');
      });
      this.map.on('draw.selectionchange', this.onSelectionChange);
    });
    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.resizeObserver.observe(this.$refs.mapContainer);
    window.addEventListener('resize', this.handleWindowResize);
    const dmodes = {
      ...MapboxDraw.modes,
      draw_rectangle: DrawRectangle,
      draw_circle: CircleMode,
      drag_circle: DragCircleMode,
      direct_select: DirectMode,
      simple_select: SimpleSelectMode
    };
    const Draw = new MapboxDraw({
      defaultMode: "simple_select",
      userProperties: true,
      displayControlsDefault: false,
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      },
////////////////////////////////////////
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          "id": "gl-draw-line",
          "type": "line",
          "filter": ["==", "$type", "LineString"],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 1
          }
        },
        // polygon fill
        {
          "id": "gl-draw-polygon-fill",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "foreign", false]],
          "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.1
          }
        },
        // polygon mid points
        // {
        //   'id': 'gl-draw-polygon-midpoint',
        //   'type': 'circle',
        //   'filter': ['all',
        //     ['==', '$type', 'Point'],
        //     ['==', 'meta', 'midpoint']],
        //   'paint': {
        //     'circle-radius': 3,
        //     'circle-color': '#fbb03b'
        //   }
        // },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        // {
        //   "id": "gl-draw-polygon-stroke-active",
        //   "type": "line",
        //   "filter": ["all", ["==", "$type", "Polygon"], ["==", "foreign", false]],
        //   "layout": {
        //     "line-cap": "round",
        //     "line-join": "round"
        //   },
        //   "paint": {
        //     "line-color": "#D20C0C",
        //     "line-dasharray": [0.2, 2],
        //     "line-width": 1
        //   }
        // },
        // vertex point halos
        // {
        //   "id": "gl-draw-polygon-and-line-vertex-halo-active",
        //   "type": "circle",
        //   "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
        //   "paint": {
        //     "circle-radius": 5,
        //     "circle-color": "#FFF"
        //   }
        // },
        // vertex points
        // {
        //   "id": "gl-draw-polygon-and-line-vertex-active",
        //   "type": "circle",
        //   "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
        //   "paint": {
        //     "circle-radius": 3,
        //     "circle-color": "#D20C0C",
        //   }
        // },
        // {
        //   'id': 'highlight-active-points',
        //   'type': 'circle',
        //   'filter': ['all',
        //     ['==', '$type', 'Point'],
        //     ["==", "foreign", false],
        //     ['==', 'meta', 'feature'],
        //     ['==', 'active', 'true']],
        //   'paint': {
        //     'circle-radius': 7,
        //     'circle-color': '#000000'
        //   }
        // },
        // {
        //   'id': 'points-are-blue',
        //   'type': 'circle',
        //   'filter': ['all',
        //     ["==", "foreign", false],
        //     ['==', '$type', 'Point'],
        //     ['==', 'meta', 'feature'],
        //     ['==', 'active', 'false']],
        //   'paint': {
        //     'circle-radius': 5,
        //     'circle-color': '#00ddcc'
        //   }
        // }
      ]
/////////////////////////////////////
    });
    this.map.addControl(Draw);
    this.draw = Draw;
    iicepStore.data.mapRef = map;
    iicepStore.data.mapDrawRef = Draw;
    this.$store.watch(
        () => this.$store.getters.getTempCoords,
        (newCoords) => {
          if (newCoords) {
            if (newCoords.length === 0) {
              newCoords = this.map.getCenter();
            }
            if (!this.tempPin) {
              this.tempPin = new mapboxgl.Marker({draggable: true})
                  .setLngLat(newCoords)
                  .addTo(this.map);
              this.tempPin.on('dragend', () => {
                const lngLat = this.tempPin.getLngLat();
                this.$store.commit('setTempCoords', lngLat);
              });
            } else {
              this.tempPin.setLngLat(newCoords);
            }
            this.map.setCenter(newCoords);
          } else {
            if (this.tempPin) {
              this.tempPin.remove();
              this.tempPin = null;
            }
          }
        }
    );
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    window.removeEventListener('resize', this.handleWindowResize);

    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    onSelectionChange: function(e) {
      const selectedFeatures = e.features;
      if (selectedFeatures.length > 0) {
        const feature = selectedFeatures[0];
        this.$store.commit('setDrawFtr', feature.id);
        this.$store.commit('setDrawSelected', true);
        this.$store.commit('setDrawFtrMsg',feature.properties.msg);
      } else {
        this.$store.commit('setDrawSelected', false);
        this.$store.commit('setDrawFtr', null);
        this.$store.commit('setDrawFtrMsg','');
      }
        // Modify an attribute of the selected feature
        // this.updateFeatureAttribute(feature.id, 'customProperty', 'newValue');
      console.log(e);
    },
    unload_anno_data: function() {
      console.log(JSON.stringify(this.$store.state.annoFtrSrc));
      console.log("Draw_save_edits:",this.$store.state._draw_save_edits);
      if (this.$store.state._draw_save_edits) {
        let ddata = this.draw.getAll();
        console.log(JSON.stringify(ddata));
        this.$store.commit('setAnnoEdits', true);
        this.$store.commit('updateAnnosAfterDraw', ddata);
      }
      this.draw.deleteAll();
      this.map.setLayoutProperty('anno_lines', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_polygons', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_rects', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_circles', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_points', 'visibility', 'visible');
      // this.reloadSource(this.dmodes[m].sname, this.draw.getAll());
      // this.map.setLayoutProperty(this.dmodes[m].lname, 'visibility', 'visible');
      // console.log('unload ',m);
      // console.log(JSON.stringify(ddata));
    },
    load_anno_data: function() {
      this.map.setLayoutProperty('anno_lines', 'visibility', 'none');
      this.map.setLayoutProperty('anno_polygons', 'visibility', 'none');
      this.map.setLayoutProperty('anno_rects', 'visibility', 'none');
      this.map.setLayoutProperty('anno_circles', 'visibility', 'none');
      this.map.setLayoutProperty('anno_points', 'visibility', 'none');
      console.log(JSON.stringify(this.$store.state.annoFtrSrc));
      this.draw.set(this.$store.state.annoFtrSrc);
      // this.createEvents('anno_lines');
      // if (this.dmodes[m].ftrs) {
      //   console.log(JSON.stringify(this.$store.state[this.dmodes[m].ftrs]));
      //   this.draw.deleteAll();
      //   this.map.setLayoutProperty(this.dmodes[m].lname, 'visibility', 'none');
      //   if (!!this.$store.state[this.dmodes[m].ftrs]) {
      //     this.draw.add(this.$store.state[this.dmodes[m].ftrs]);
      //   }
      // }
      console.log('load:');
    },
    createEvents(lname) {
      this.map.on('click', lname, (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        let html = '<table>';
        Object.keys(e.features[0].properties).forEach(k => {
          html = html + `<tr><td>${k}</td><td>${e.features[0].properties[k]}</td></tr>`;
        })
        html += '</table>';
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);
      });
      this.map.on('mouseenter', lname, () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', lname, () => {
        this.map.getCanvas().style.cursor = '';
      });
    },
    setStreetMap() {
      this.sat_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','none');
      })
      this.str_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','visible');
      })
    },
    setSatelliteMap() {
      this.sat_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','visible');
      })
      this.str_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','none');
      })
    },
    handleWindowResize() {
      this.map.resize();
    },
    reloadSource(src, ftr_list) {
      if (this.map && this.map.getSource(src)) {
        const geojson = {
          'type': 'FeatureCollection',
          'features': (!!ftr_list ? ftr_list : [])
        };
        this.map.getSource(src).setData(geojson);
      }
    },
    addMarkerIcons() {
      const combinedArray = [...annoIcons, ...incIcons.map(({ ID: value, Symbol: icon, Name: label}) => ({ value, icon, label }))];
      let self = this;
      const imagePromises = combinedArray.map(irec => {
        return new Promise((resolve, reject) => {
          self.map.loadImage(`./assets/${irec.icon}`, (error, image) => {
            if (error) {
              reject(error);
            } else {
              if (!self.map.hasImage('im' + irec.value)) {
                console.log('added im' + irec.value);
                self.map.addImage('im' + irec.value, image);
              }
              resolve();
            }
          });
        });
      });
      return Promise.all(imagePromises);
    }
  },
}
</script>

<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<style scoped>
.map-container {
  flex: 1;
}
</style>