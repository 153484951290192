<script setup>
import iicepStore from "@/iicepStore.js";
import Mapview from "@/components/mapview.vue";
import lineimg from "@/assets/line.png"
import polygonimg from "@/assets/polygon.png"
import rectimg from "@/assets/rectangle.png"
import circleimg from "@/assets/circle.png"
import pinimg from "@/assets/pin.png"
import panimg from "@/assets/pan.png"
import { mapState } from "vuex";
import polyline from '@mapbox/polyline'
import activityLog from "@/components/activityLog.vue";
import {annoColors, annoIcons, annoColorNames} from "@/utils.js";
</script>

<template>
    <v-navigation-drawer theme="dark" rail expand-on-hover permanent location="left">
      <v-list density="compact" nav>
        <v-list-item
            prepend-icon="mdi-record-circle"
            value="Events"
            title="Events"
            @click="goToEvents"
        >
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-forum"
            value="Activity_Log"
            title="Activity Log"
        >
          <activity-log
              button-density="default"
              button-label="Activity Log"
              button-size="default"
          ></activity-log>
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-circle-double"
            value="Incidents"
            title="Incidents"
            @click="goToIncidents"
        >
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-wrench"
            value="ResourceRequests"
            title="Resource Requests"
            @click="goToResReqs"
        >
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-microsoft-azure"
            value="Shelters"
            title="Shelter management"
            @click="goToShelters"
        >
        </v-list-item>
      </v-list>
      <v-list density="compact" nav>
        <v-list-item
            prepend-icon="mdi-logout"
            value="Dashboard"
            title="Dashboard"
            @click="goToDashboard()"
        >
        </v-list-item>
      </v-list>
        <template v-slot:append>
          <div>
            <v-btn
                icon="mdi-chevron-left"
                variant="text"
                @click.stop="resizePanel"
            ></v-btn>
          </div>
        </template>
    </v-navigation-drawer>
    <v-navigation-drawer
        permanent
        v-model="drawer"
        location="right"
        :rail="rail"
        rail-width="100"
        @click="rail=false"
    >
      <v-list-item
          title="Map"
          nav
      >
        <template v-slot:append>
          <v-btn
              icon="mdi-chevron-left"
              variant="text"
              @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>
      <v-select
          class="px-1"
          :items="iicepStore.data.layers"
          item-title="label"
          item-value="label"
          variant="outlined"
          density="compact"
          label="Layers"
          multiple
          v-model="selectedItems"
          hide-details
      >
        <template v-slot:selection></template>
      </v-select>
      <v-list
          class="py-0"
      >
        <v-list-item
            class="py-0"
        >
          <v-switch
              v-model="mapModeToggle"
              @change="changeMode"
              hide-details
              density="compact"
          >
            <template v-slot:label>Street map</template>
            <template v-slot:prepend>Satellite</template>
          </v-switch>
        </v-list-item>
        <v-list-item>
          <v-btn
              variant="outlined"
              density="comfortable"
              v-if="!draw_edit_tg"
              @click="startAnnoEdit"
          >Edit Annotations</v-btn>
          <v-btn
              variant="outlined"
              density="comfortable"
              v-if="draw_edit_tg"
              @click="saveEdits"
          >Save</v-btn>
          <v-btn
               class="ml-2"
              variant="outlined"
              v-if="draw_edit_tg"
              density="comfortable"
              @click="cancelEdits"
          >Discard</v-btn>
        </v-list-item>
        <v-list-item
            class="pr-0"
            v-if="draw_edit_tg"
        >
          <v-row no-gutters>
            <v-col class='leftc'>
              <span>Draw</span>
              <v-btn icon @click="setDrawingMode('simple_select')">
                <img alt="pan" :src="panimg" :class="{clicked: drawmode==='simple_select'}" class="icon-sprite" />
              </v-btn>
              <v-btn icon @click="setDrawingMode('draw_line_string')">
                <img alt="line" :src="lineimg" :class="{clicked: drawmode==='draw_line_string'}" class="icon-sprite" />
              </v-btn>
              <v-btn icon @click="setDrawingMode('draw_polygon')">
                <img alt="polygon" :src="polygonimg" :class="{clicked: drawmode==='draw_polygon'}" class="icon-sprite" />
              </v-btn>
              <v-btn icon @click="setDrawingMode('draw_rectangle')">
                <img alt="rectangle" :src="rectimg" :class="{clicked: drawmode==='draw_rectangle'}" class="icon-sprite" />
              </v-btn>
              <v-btn icon @click="setDrawingMode('draw_circle')">
                <img alt="circle" :src="circleimg" :class="{clicked: drawmode==='draw_circle'}" class="icon-sprite" />
              </v-btn>
              <v-btn icon @click="setDrawingMode('draw_point')">
                <img alt="pan" :src="pinimg" :class="{clicked: drawmode==='draw_point'}" class="icon-sprite" />
              </v-btn>
            </v-col>
            <v-col>
              <span v-if="drawmode!=='draw_point'">Lines and Areas</span>
              <span v-if="drawmode==='draw_point'">Markers</span>
              <v-select
                  class="pt-1 mb-5"
                  v-if="drawmode!=='draw_point'"
                  :items="annoColorNames"
                  item-value="value"
                  item-title="label"
                  variant="outlined"
                  density="compact"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <div :class="['squarecls', 'mr-2', item.raw.cl]"/>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                  class="pt-1 mb-5"
                  v-if="drawmode==='draw_point'"
                  :items="annoIcons"
                  item-value="value"
                  item-title="label"
                  variant="outlined"
                  density="compact"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <img :src="'./assets/'+item.raw.icon" height="20" width="20" :alt="item.raw.label" class="pr-1"/>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-spacer/>
              <v-text-field
                  v-model="draw_anno_msg"
                  class="mt-10"
                  v-if="draw_anno_selected"
                  variant="outlined"
                  density="compact"
                  label="Annotation message"
                  persistent-hint
              >
              </v-text-field>
              <v-btn
                  v-if="draw_anno_selected"
                  variant="outlined"
                  class="ma-0"
                  block
                  density="comfortable"
              >
                DELETE THIS
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
        location="left"
        permanent
        :width="sidePanel"
    >
      <router-view></router-view>
    </v-navigation-drawer>
    <v-bottom-navigation>
      <v-btn value="recent">
        Support request
      </v-btn>
      <v-btn value="favorites">
        Help
      </v-btn>
    </v-bottom-navigation>
    <mapview/>
</template>
<script>
  import iicepStore from "@/iicepStore.js";
  import {mapState} from "vuex";

  export default {
    name: 'oem_main',
    props: {},
    data: () => ({
      rail:true,
      drawer:true,
      selectedItems: [],
      isClicked: false,
      drawmode:'pan',
      mapModeToggle: false,
      draw_edit_tg: false,
      draw_save_tg: false,
      draw_anno_selected: false,
      draw_anno_msg: '',
      // draw_delete_tg: false
    }),
    methods: {
      startAnnoEdit: function() {
        this.draw_edit_tg=true;
        this.$store.commit('setEditMode', this.draw_edit_tg);
      },
      stopAnnoEdit: function() {
        this.draw_edit_tg=false;
        this.$store.commit('setDrawSave', this.draw_save_tg)
        this.$store.commit('setEditMode', this.draw_edit_tg);
      },
      saveEdits: function() {
        this.draw_save_tg=true;
        this.stopAnnoEdit();
      },
      cancelEdits: function() {
        this.draw_save_tg=false;
        this.stopAnnoEdit();
      },
      changeMode: function() {
        this.$store.commit('setMapMode', this.mapModeToggle?'str_map':'sat_map');
      },
      setDrawingMode: function(m) {
        this.$store.commit('setDrawMode', m);
        this.drawmode = m;
      },
      resizePanel: function () {
        this.$store.commit('invertCollapsed');
      },
      goToDashboard: function(){
        let self = this;
        self.$router.push({name:"home"})
      },
      goToShelters: function(){
        let self = this;
        self.$router.push({name:"shelters"})
      },
      goToResReqs: function(){
        let self = this;
        self.$router.push({name:"resreqs"})
      },
      goToIncidents: function(){
        let self = this;
        self.$router.push({name:"incidents"})
      },
      goToOnlineusers: function() {
        this.$store.dispatch('retrieveOnlineUsers');
        this.$router.push('/oem/online')
      },
      goToEvents: function(){
        let self = this;
        self.$router.push({name:"events"})
      },
    },
    watch: {
      _draw_anno_selected(newval) {
        this.draw_anno_selected = newval ;
      },
      _draw_anno_msg(newval) {
        this.draw_anno_msg= newval;
      },
      _draw_anno_id(newval) {
      },
      selectedItems: {
        handler(newVal) {
          if (iicepStore.data.oem_init) {
            let map1 = iicepStore.data.mapRef;
            iicepStore.data.layers.forEach(item => {
              let newstate = newVal.includes(item.label);
              if (item.state !== newstate) {
                let newvis = newstate ? 'visible' : 'none';
                item.state = newstate;
                if (item.type === 'layer')
                  map1.setLayoutProperty(item.id, 'visibility', newvis);
                if (item.type === 'group') {
                  item.members.forEach(mem => {
                    map1.setLayoutProperty(mem, 'visibility', newvis);
                  })
                }
              }
            });
          }
        },
      },

    },
    computed: {
      ...mapState(['_draw_start_edit','_draw_cancel_edits','_draw_save_edits','_draw_delete_sel','_draw_anno_selected',
      '_draw_anno_msg', '_draw_anno_id']),
      sidePanel()  {
        return (this.$route.meta.mapSidePanel && !this.$store.getters.getCollapsed)?700:2;
      }
    },
    mounted() {
      iicepStore.data.layers.forEach(item => {
        if (item.state) this.selectedItems.push(item.label);
      })
    }
  }
</script>
<style scoped>
.icon-sprite {
  width: 50px;
  height: 50px;
  object-fit: none;
  object-position: 0 0;
}
.leftc {
  max-width: 52px !important;
}
.icon-sprite.clicked {
  object-position: 0 -50px;
}
.squarecls {
  display: inline-block;
  height: 20px;
  width: 20px;
}
</style>
