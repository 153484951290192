<script>
export  default {
  components: { },
  methods: {
    appendIconCallback () {
      alert('click:append')
    },
    prependIconCallback () {
      alert('click:prepend')
    },
  },
  data() {
    return {
      tab: null,
      reportVersion: 0,
      dialog: false,
    }
  }
}
</script>

<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="50vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-bind="props"
            icon="mdi-state-machine"
            density="compact"
            variant="flat"
            size="small"
        ></v-btn>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">
          <span class="text-h5"> Event Status</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-1">
                <v-row class="d-flex flex-wrap justify-space-evenly mb-2">
                  <v-col
                      id="paragraf1"
                      class="text-center mr-2 rounded"
                      cols="3"
                  > <p> Assistance Required</p> </v-col>
                  <v-col
                      id="paragraf2"
                      class="text-center ml-2 mr-2 rounded"
                      cols="3"
                  > <p> Moderate</p> </v-col>
                  <v-col
                      id="paragraf3"
                      class="text-center ml-2 rounded"
                      cols="3">
                    <v-input
                        class="d-flex justify-center"
                        append-icon="mdi-plus"
                        prepend-icon="mdi-minus"
                        @click:append="reportVersion++"
                        @click:prepend="reportVersion--"
                    > Revision: {{reportVersion}} </v-input>
                  </v-col>
                </v-row>
                <v-row class="d-flex flex-column ml-5 mb-2">
                  <v-col
                      class="ml-3 pa-1  pb-0"
                      cols="12"
                  >
                    <p> Event name: </p>
                  </v-col>
                  <v-col  class="ml-2 pa-1   pb-2"
                          cols="12">
                    <p> Created by: </p>
                  </v-col>
                  <v-col  class="ml-2 pa-1  pb-2"
                          cols="12">
                    <p> Event address: </p>
                  </v-col>

                  <v-col  class="ml-2 pa-1  pb-2"
                          cols="12">
                    <p> Event type: </p>
                  </v-col>

                  <v-col  class="ml-2 pa-1  pb-2"
                          cols="12">
                    <p> SitRep created: </p>
                  </v-col>

                  <v-col  class="ml-2 pa-1  pb-2"
                          cols="12">
                    <p> Last update: </p>
                  </v-col>
                </v-row>

                <v-container>
                  <v-tabs
                      grow
                      v-model="tab"
                      bg-color="brown"
                      align-tabs="center"
                      class="rounded"

                  >
                    <v-tab value="sitrep">SitRep</v-tab>
                    <v-tab value="messages">Messages</v-tab>
                    <v-tab value="docs">Documents</v-tab>
                  </v-tabs>
                  <v-window v-model="tab">
                    <v-window-item value="sitrep">
                        <v-col class="ml-2 pa-1 pb-0">
                          <p> Event name: </p>
                        </v-col>
                        <v-col class="ml-2 pa-1   pb-2">
                          <p> Created by: </p>
                        </v-col>
                        <v-col class="ml-2 pa-1  pb-2">
                          <p> Event address: </p>
                        </v-col>
                        <v-col class="ml-2 pa-1  pb-2">
                          <p> Event type: </p>
                        </v-col>
                        <v-col class="ml-2 pa-1  pb-2">
                          <p> SitRep created: </p>
                        </v-col>
                        <v-col  class="ml-2 pa-1  pb-2">
                          <p> Last update: </p>
                        </v-col>
                    </v-window-item>
                    <v-window-item value="messages">
                      <v-table
                          class="sitrepMessagesTable"
                          id="sitrepMessagesTable"
                          fixed-header
                          density="compact">
                        <thead>
                        <tr>
                          <th class="text-left"> Rev </th>
                          <th class="text-left"> Time </th>
                          <th class="text-left"> Messages  </th>
                          <th class="text-left"> Priority   </th>
                          <th class="text-left"> User   </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td> 3 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> Pilesgrove Township EOC Activation Form submitted </td>
                          <td>  </td>
                          <td> John Doe </td>
                        </tr>
                        <tr>
                          <td> 2 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> Pilesgrove Township EOC Activation Form submitted </td>
                          <td>  </td>
                          <td> John Doe </td>
                        </tr>
                        <tr>
                          <td> 1 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> Pilesgrove Township EOC Activation Form submitted </td>
                          <td>  </td>
                          <td> John Doe </td>
                        </tr>
                        </tbody>
                      </v-table>
                    </v-window-item>
                    <v-window-item value="docs">
                      <v-table
                          class="sitrepMessagesTable"
                          id="sitrepDocumentsTable"
                          fixed-header
                          density="compact">
                        <thead>
                        <tr>
                          <th class="text-left"> Rev </th>
                          <th class="text-left"> Time </th>
                          <th class="text-left"> Documents  </th>
                          <th class="text-left"> User   </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td> 3 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> img.jpg </td>
                          <td> John Doe </td>
                        </tr>
                        <tr>
                          <td> 2 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> img.jpg </td>
                          <td> John Doe </td>
                        </tr>
                        <tr>
                          <td> 1 </td>
                          <td> 2023/04/26 13:24:52 </td>
                          <td> img.jpg </td>
                          <td> John Doe </td>
                        </tr>
                        </tbody>
                      </v-table>
                    </v-window-item>
                  </v-window>
                </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-3 ml-1 pa-0"
              variant="outlined"
              @click="dialog = false"
              size="large"
              width="30%"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-3 mr-1 pa-0"
              variant="outlined"
              @click="dialog=false"
              size="large"
              width="30%"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<style>
#paragraf1 {
  background: #ff4921;
  color: #ffde00;
  border-style: solid;
  border-color: black;
  border-width: thin;
  height: 50px;
}
#paragraf2{
  background: #ffde00;
  color: #3266ff;
  border-style: solid;
  border-color: black;
  border-width: thin;
  height: 50px;
}
#paragraf3{
  border-style: solid;
  border-color: black;
  border-width: thin;
  height: 50px;
  padding-right: 10px;
}


</style>