<script>
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
export default {
  name: "sitrepmsg",
  methods: {
    cancelMsg: function () {
      this.$parent.$parent.shownewmsg=false;
    },
    sendSitrepMessage: function () {
      let self=this ;
      if (self.msgText !== '') {
        self.$parent.$parent.shownewmsg=false;
        if (document.getElementById("adddoc").files.length == 0) {
          let idata = {
            msgText: self.msgText,
            msgDir: self.msgInout,
            priority: self.msgPri,
            immed: self.msgImm,
            copycnty: self.msgPropagate,
            fileId: -1,
            mode: "saveicsmsg"
          };
          $.ajax({
            url: "/saveData.php",
            type: "POST",
            data: idata,
            success: function (resp) {
              if (resp.Status === 'OK') {
                self.$parent.$parent.shownewmsg = false;
              }
            }
          });
        } else {
          let data = new FormData();
          data.append('ufile', $('#adddoc')[0].files[0]);
          $.ajax({
            url: "/uploadToSitrep.php",
            type: "POST",
            enctype: 'multipart/form-data',
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            success: function (jdata) {
              let idata = {
                msgText: self.msgText,
                msgDir: self.msgInout,
                priority: self.msgPri,
                immed: self.msgImm,
                copycnty: self.msgPropagate,
                fileId: jdata.ID,
                mode: "saveicsmsg"
              };
              $.ajax({
                url: "/saveData.php",
                type: "POST",
                data: idata,
                success: function (resp) {
                  if (resp.Status === 'OK') {
                    self.$parent.$parent.shownewmsg = false;
                  }
                }
              });
            }
          });
        }
      } else {
        alert ("Please enter message text");
      }
    }
  },
  data:function(){
    return {
      msgInout:"IN",
      msgImm:false,
      msgPri:false,
      msgPropagate:true,
      msgText:""
    }
  }
}
</script>
<template>
    <div>
        <div class="xrow5"><h2 class="win_h2a">Enter Activity Log Message</h2></div>
        <div class="xrow5"><input id="win8b1" placeholder="Enter Message here" title="" v-model="msgText"/></div>
        <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xl-1">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="inoutrad" id="radin" value="IN" v-model="msgInout">
                    <label class="form-check-label" for="radin">IN</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="inoutrad" id="radout" value="OUT" v-model="msgInout">
                    <label class="form-check-label" for="radout">OUT</label>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="chkimm" id="chkimm" value="Immediate" v-model="msgImm">
                    <label class="form-check-label" for="chkimm">Immediate</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="chkpri" id="chkpri" value="Priority" v-model="msgPri">
                    <label class="form-check-label" for="chkpri">Priority</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="chkpub" id="chkpub" value="Publish" v-model="msgPropagate">
                    <label class="form-check-label" for="chkpub">Publish to Municipal Sitrep Logs</label>
                </div>
                <p class="card-text">
                    This Activity Log Message,when checked, will publish to the running log of the County as the Lead or supporting Agency engaged in management of this Major Event</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                Add document <input class="form-control-file input-group-sm" type="file" name="adddoc" id="adddoc"/>
            </div>
        </div>
        <div class="xrow7"><button id="win8b9" @click="cancelMsg">Cancel</button><button id="win8b10" @click="sendSitrepMessage">Submit</button></div>
    </div>
</template>
<style scoped>
    .det-top {
        font-size: 16px;
    }
    .row {
        margin: 0;
    }
    .rdiv {
        float: right;
        font-size: 16px;
    }
    .ldiv {
        float: left;
        font-size: 16px;
    }
    .rdiv50 {
        float: right;
        width:48%;
        min-width:200px;
        padding: 0 10px 0 10px;
        font-size: 16px;
        text-align: left;
    }
    .ldiv50 {
        float: left;
        padding: 0 10px 0 10px;
        font-size: 16px;
        width:48%;
        min-width:200px;
        text-align: left;
    }
    .event h3 {
        height: 25px;
    }
    .det-top {
        display: block;
    }
    ul {
        list-style-type: square;
        padding: 0;
    }
    a {
        color: #011efe;
    }
    .message-list {
        overflow-y: auto;
        text-align: left;
    }
    .details p {
        margin-top: 5px;
    }
    .xrow5 {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 7px;
        text-align: left;
        clear: both;
        overflow: auto;
    }
    .xrow7 {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        text-align: left;
        clear: both;
        overflow: auto;
    }
    .win_h2a {
        font: 30px Cambria, Arial, sans-serif;
        font-weight: bold;
        width: 95%;
        text-align: left;
        margin: auto;
        padding: 5px;
        letter-spacing: -1px;
        color: #C55C28;
    }
    .td2 {
        clear: both;
        vertical-align: top;
        font-size: 14px;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
        padding : 5px 5px 5px 15px;
    }
    th {
        clear: both;
        vertical-align: top;
        font-size: 14px;
        text-align: left;
        background-color: #7c93c0;
        font-weight: bold;
        padding : 5px 5px 5px 15px;
    }
    .tab {
        width: 100%;
    }
    tr:nth-child(even) {background: #CCC}
    tr:nth-child(odd) {background: #FFF}
    #win8b9 {
        height: 40px;
        width: 377px;
        float: left;
        display: inline-block;
        margin: 10px 0 0 10px;
        box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        color: #FFFFFF;
        text-shadow: none;
        border-color: transparent;
        background-color: #BDBEC0;
    }
    #win8b10 {
        height: 40px;
        width: 377px;
        float: right;
        display: inline-block;
        margin: 10px 10px 0 0;
        box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        color: #FFFFFF;
        text-shadow: none;
        border-color: transparent;
        background-color: #C55C28;
    }
    #win8b1 {
        width: 770px;
        height: 30px;
        color: #666666;
        text-shadow: none;
        border-color: #C8C6C6;
        background-color: #F2F2F3;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        font-family: Cambria, Georgia, "Times New Roman", serif;
        -webkit-appearance: none;
        padding: 0 0 0 0;
        outline: none;
        border-width: 1px;
        border-style: solid;
    }
    .msgpost {
        white-space: normal;
    }
    tr:nth-child(even) {background: #CCC}
    tr:nth-child(odd) {background: #FFF}
    .lastrow {
        padding-bottom: 5px;
    }

</style>
