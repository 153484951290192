export const map911Config = {
    initialMode: 'street',
    modeLayersList: {
        street: [
            'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
            'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
            'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
            'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
        ],
        satellite: [
            'satellite'
        ]
    },
    iicepLayers: [
        { 'label':'ERPA', 'id':'erpa-ll-1oikwz', 'state': false, 'type':'layer'},
        { 'label':'Dams(Salem)', 'id':'dams1-66ktwt', 'state': false, 'type':'layer'},
        { 'label':'Dams(NJ)', 'id':'dams2-bcw6vq', 'state': false, 'type':'layer'},
        { 'label':'Sirens', 'id':'sirens-salem', 'state': false, 'type':'layer'},
        { 'label':'Congregate Care', 'id':'congregate-care', 'state': false, 'type':'layer'},
        { 'label':'Bus Routes', 'id':'bus-routes', 'state': false, 'type':'group', 'members':['bus-routes-lines', 'bus-routes-arrows']}
    ],
    iconslist: [
        {value: "311", icon: "311.png", label: "Road Closure"},
        {value: "406", icon: "406.png", label: "Detour"},
        {value: "430", icon: "430.png", label: "Flood"},
        {value: "421", icon: "421.png", label: "Major Fire"},
        {value: "EMS", icon: "ems32.png", label: "EMS"},
        {value: "FIRE", icon: "fire32.png", label: "FIRE"},
        {value: "FIREEMS", icon: "fireems32.png", label: "FIREEMS"},
    ]
}