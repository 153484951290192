<script>
import {getLogs} from '@/services.js'

export default {
  name: "userActivityLog",
  data: () => ({
    dialog: false,
    showStartDatePicker: false,
    showEndDatePicker: false,
    actiontypes: [
      {value: 0, text: 'Any'},
      {value: 1, text: 'System Actions'},
      {value: 2, text: 'Dashboard Actions'},
      {value: 3, text: 'Document Repository Events'},
      {value: 4, text: 'Major Event Actions'},
      {value: 5, text: 'Incident Actions'},
      {value: 6, text: 'Resource Request Actions'},
      {value: 7, text: 'Center Actions'}
    ],
    aGroup: 0,
    user: '',
    startDate: null,
    endDate: null,
    dateRange: [],
    loglist: []
  }),
  computed: {
    formattedStartDate() {
      return this.startDate ? this.formatDate(this.startDate) : '';
    },
    formattedEndDate() {
      return this.endDate ? this.formatDate(this.endDate) : '';
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = d.getFullYear();
      return `${month}/${day}/${year}`;
    },
    formatDateN(date) {
      if (!date) return '';
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    },
    onStartDateSelected(date) {
      this.showStartDatePicker = false;
      this.startDate = date;
    },
    onEndDateSelected(date) {
      this.showEndDatePicker = false;
      this.endDate = date;
    },
    resetDates() {
      this.startDate=null;
      this.endDate=null;
    },
    async getData() {
      const fd = new FormData();
      const paramObj = {};
      if (!!this.user && this.user!=='') {
        paramObj.name = this.user;
        fd.append('name', this.user);
      }
      if (!!this.aGroup) {
        paramObj.act = this.aGroup;
        fd.append('act', this.aGroup);
      } else {
        paramObj.act = this.aGroup;
        fd.append('act', this.aGroup);
      }
      if (!!this.startDate && this.startDate>'') {
        paramObj.from = this.formatDateN(this.startDate);
        fd.append('from', this.formatDateN(this.startDate));
      }
      if (!!this.endDate && this.endDate>'') {
        paramObj.to = this.formatDateN(this.endDate);
        fd.append('to', this.formatDateN(this.endDate));
      }
      const resp = await getLogs(paramObj);
      if (resp['Status']==='OK') {
        this.loglist = resp.result;
      } else {
        this.loglist = [];
      }
    },
  },
  mounted: function () {
    this.loglist = [];
  }
}
</script>

<template>
  <v-dialog
      persistent
      theme="light"
      width="90vw"
      height="80vh"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
          class="ma-1"
          size="small"
          v-bind="activatorProps"
          variant="outlined"
          @click="getData"
      >
        User Activity
      </v-btn>
    </template>
    <template v-slot:default="{ isActive }">
    <v-card class="elevation-12">
      <v-toolbar color="teal" dark flat>
        <v-toolbar-title>IICEP - User Activity</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" lg="2">
            <v-text-field
                variant="outlined"
                density="compact"
                v-model="formattedStartDate"
                label="Start date"
                prepend-icon="mdi-calendar"
                persistent-hint
                hide-details
                readonly
                @click="showStartDatePicker = !showStartDatePicker"
            ></v-text-field>
            <v-date-picker
                v-model="startDate"
                elevation="24"
                :max="endDate"
                v-if="showStartDatePicker"
                @update:model-value="onStartDateSelected"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-text-field
                v-model="formattedEndDate"
                variant="outlined"
                density="compact"
                label="End date"
                persistent-hint
                hide-details
                prepend-icon="mdi-calendar"
                readonly
                @click="showEndDatePicker = !showEndDatePicker"
            ></v-text-field>
            <v-date-picker
                v-model="endDate"
                width="400"
                elevation="24"
                :min="startDate"
                v-if="showEndDatePicker"
                @update:model-value="onEndDateSelected"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-text-field
                v-model="user"
                variant="outlined"
                density="compact"
                label="User Name or Email"
            />
          </v-col>
          <v-col cols="9" md="3" lg="2">
            <v-select
                v-model="aGroup"
                :items="actiontypes"
                density="compact"
                variant="outlined"
                item-title="text"
                item-value="value"
                label="Action Type"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" lg="4" >
              <v-btn
                  variant="outlined"
                  @click="resetDates"
              >
                Reset
              </v-btn>
            <v-btn
                class="mr-2 ml-2"
                variant="outlined"
                @click="getData"
            >
              Show
            </v-btn>
            <v-btn class="ml-2"
                  variant="outlined"
                  text="Close"
                  @click="isActive.value = false"
              ></v-btn>
            </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 font-weight-black text-subtitle-1" cols="4">
            User Name, email
          </v-col>
          <v-col class="pa-0 font-weight-black text-subtitle-1" cols="2">
            Activity
          </v-col>
          <v-col class="pa-0 font-weight-black text-subtitle-1" cols="4">
            Details
          </v-col>
          <v-col class="pa-0 font-weight-black text-subtitle-1" cols="2">
            Time
          </v-col>
        </v-row>
        <v-row v-for="(entry,i) in loglist" :key="i">
          <v-col class="pa-0 text-subtitle-1" cols="4">
            {{ entry.userName }}, {{ entry.userEmail }}<br/>{{ entry.juriName }}
          </v-col>
          <v-col class="pa-0 text-subtitle-1" cols="2">
            {{ entry.actionType }}
          </v-col>
          <v-col class="pa-0 text-subtitle-1" cols="4">
            {{ entry.subjectText }}
          </v-col>
          <v-col class="pa-0 text-subtitle-1" cols="2">
            {{ entry.actionTime }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>

</style>