<script>
import iicepStore from "@/iicepStore.js";
import mapboxgl from "mapbox-gl";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {annoIcons, incIcons} from '@/utils.js';
import {mapState} from "vuex";
mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ'
export default {
  name: 'mapnodraw',
  data: () => ({
    map: null,
    resizeObserver: null,
    maplayers: [],
    incident_pins: [],
    event_pins: [],
    rr_pins: [],
    own_anno_pins: [],
    foreign_anno_pins: [],
    current_map: 'sat_map',
    sat_map:[
        'satellite'
    ],
    str_map:[
        'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
        'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
        'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
        'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
    ],
    sourcedefs: {
      anno_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      inc_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      ev_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      rr_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      law_ftrs_src: {
        type: 'geojson',
        data: './assets/law.geojson'
      },
      fire_ftrs_src: {
        type: 'geojson',
        data: './assets/fire.geojson'
      },
    },
    layerdefs: [
      {
        id: 'law_polygons',
        type: 'fill',
        source: 'law_ftrs_src',
        filter: ["==", ["geometry-type"], "Polygon"],
        paint: {
          'fill-color': '#5555ff',
          'fill-opacity': 0.4,
          'fill-outline-color': '#0000ff'
        }
      }, {
        id: 'fire_polygons',
        type: 'fill',
        source: 'fire_ftrs_src',
        filter: ["==", ["geometry-type"], "Polygon"],
        paint: {
          'fill-color': '#ff5555',
          'fill-opacity': 0.4,
          'fill-outline-color': '#ff0000'
        }
      }, {
      id: 'anno_polygons',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_rects',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_circles',
      type: 'fill',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        'fill-color': ['get', 'clr'],
        'fill-opacity': 0.4,
        'fill-outline-color': ['get', 'clr']
      }
    }, {
      id: 'anno_lines',
      type: 'line',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "LineString"],
      paint: {
        'line-color': ['get', 'clr'],
        'line-width': 4
      }
    }, {
      id: 'anno_points',
      type: 'symbol',
      source: 'anno_ftrs_src',
      filter: ["==", ["geometry-type"], "Point"],
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'inc_points',
      type: 'symbol',
      source: 'inc_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'ev_points',
      type: 'symbol',
      source: 'ev_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }, {
      id: 'rr_points',
      type: 'symbol',
      source: 'rr_ftrs_src',
      paint: {
        'icon-opacity': 1
      },
      layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'center',
        'icon-ignore-placement': true,
        'icon-image': ['get', 'icon']
      }
    }
    ]
  }),
  computed: {
    ...mapState(['annoList','annoFtrSrc','mapmode','inc_ftrs','ev_ftrs','rr_ftrs', 'draw_mode', '_draw_edit'])
  },
  watch: {
    mapmode(newmode) {
      if (newmode==='str_map') {
         this.setStreetMap();
      }
      if (newmode==='sat_map') {
         this.setSatelliteMap();
      }
    },
    annoFtrSrc(newdata) {
      this.map.getSource('anno_ftrs_src').setData(newdata);
    },
    annoList(newdata) {
      this.reloadSource('anno_ftrs_src', newdata);
    },
    inc_ftrs(newdata) {
      this.reloadSource('inc_ftrs_src', newdata);
    },
    ev_ftrs(newdata) {
      this.reloadSource('ev_ftrs_src', newdata);
    },
    rr_ftrs(newdata) {
      this.reloadSource('rr_ftrs_src', newdata);
    },
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.map = map;
    map.on('load', () => {
      iicepStore.data.layers.forEach(lr => {
        let lstate = lr.state ? 'visible' : 'none';
        if (lr.type === 'layer') {
          map.setLayoutProperty(lr.id, 'visibility', lstate)
        }
        if (lr.type === 'group') {
          lr.members.forEach(mem => {
            map.setLayoutProperty(mem, 'visibility', lstate);
          })
        }
      });
      iicepStore.data.oem_init = true;
      Object.keys(this.sourcedefs).forEach(k => {
        map.addSource(k, this.sourcedefs[k]);
      });
      this.layerdefs.forEach(lr => {
        map.addLayer(lr);
        console.log(map.getLayer(lr));
      })
      this.addMarkerIcons().then(() => {
        this.reloadSource('anno_ftrs_src', this.annoFtrSrc);
        this.reloadSource('inc_ftrs_src', this.inc_ftrs);
        this.createEvents('inc_points');
      });
    });
    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.resizeObserver.observe(this.$refs.mapContainer);
    window.addEventListener('resize', this.handleWindowResize);
    iicepStore.data.edbMapRef = map;
    this.$store.watch(
        () => this.$store.getters.getTempCoords,
        (newCoords) => {
          if (newCoords) {
            if (newCoords.length === 0) {
              newCoords = this.map.getCenter();
            }
            if (!this.tempPin) {
              this.tempPin = new mapboxgl.Marker({draggable: true})
                  .setLngLat(newCoords)
                  .addTo(this.map);
              this.tempPin.on('dragend', () => {
                const lngLat = this.tempPin.getLngLat();
                this.$store.commit('setTempCoords', lngLat);
              });
            } else {
              this.tempPin.setLngLat(newCoords);
            }
            this.map.setCenter(newCoords);
          } else {
            if (this.tempPin) {
              this.tempPin.remove();
              this.tempPin = null;
            }
          }
        }
    );
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    load_anno_data: function() {
      this.map.setLayoutProperty('anno_lines', 'visibility', 'none');
      this.map.setLayoutProperty('anno_polygons', 'visibility', 'none');
      this.map.setLayoutProperty('anno_rects', 'visibility', 'none');
      this.map.setLayoutProperty('anno_circles', 'visibility', 'none');
      this.map.setLayoutProperty('anno_points', 'visibility', 'none');
    },
    createEvents(lname) {
      this.map.on('click', lname, (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        let html = '<table>';
        Object.keys(e.features[0].properties).forEach(k => {
          html = html + `<tr><td>${k}</td><td>${e.features[0].properties[k]}</td></tr>`;
        })
        html += '</table>';
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);
      });
      this.map.on('mouseenter', lname, () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', lname, () => {
        this.map.getCanvas().style.cursor = '';
      });
    },
    setStreetMap() {
      this.sat_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','none');
      })
      this.str_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','visible');
      })
    },
    setSatelliteMap() {
      this.sat_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','visible');
      })
      this.str_map.forEach(l => {
        this.map.setLayoutProperty(l,'visibility','none');
      })
    },
    handleWindowResize() {
      this.map.resize();
    },
    reloadSource(src, ftr_list) {
      if (this.map && this.map.getSource(src)) {
        const geojson = {
          'type': 'FeatureCollection',
          'features': (!!ftr_list ? ftr_list : [])
        };
        this.map.getSource(src).setData(geojson);
      }
    },
    addMarkerIcons() {
      const combinedArray = [...annoIcons, ...incIcons.map(({ ID: value, Symbol: icon, Name: label}) => ({ value, icon, label }))];
      let self = this;
      const imagePromises = combinedArray.map(irec => {
        return new Promise((resolve, reject) => {
          self.map.loadImage(`./assets/${irec.icon}`, (error, image) => {
            if (error) {
              reject(error);
            } else {
              if (!self.map.hasImage('im' + irec.value)) {
                console.log('added im' + irec.value);
                self.map.addImage('im' + irec.value, image);
              }
              resolve();
            }
          });
        });
      });
      return Promise.all(imagePromises);
    }
  },
}
</script>

<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<style scoped>
.map-container {
  flex: 1;
}
</style>