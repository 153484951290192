<script>
import incidents911 from '@/components/911incidents.vue'
import notes911 from '@/components/911notes.vue'
import status911 from '@/components/911status.vue'
import {DragCol, DragRow, Resize} from "vue-resizer";
import {apiClientSimple} from "@/services.js";
import iicepStore from "@/iicepStore.js";
import {mapActions} from "vuex";
import map911 from "@/components/911map.vue";

export default {
  name: 'db911',
  data:function(){
    return {
      notesList : [],
      statusList : [],
      incidentList : [],
      loadtime :'454545',
      dialog: false,

      iicepmap: null,
      drawman: null,
      markers: [],
      linepolymarker: [],
      latestAnnoId: 0,
      currentSymbol: 0,
      infoW : null, //new google.maps.InfoWindow();
      vm: null
    }
  },
  components: {
    map911,notes911,status911,incidents911,
    DragRow, DragCol,Resize
  },
  methods: {
    ...mapActions(['fetch911Data']),
    showMlist:function(){
      // if ($('#markselect').hasClass('divhide')) {
      //   $('#selecttype').removeClass('divhide');
      //   $('#markselect').removeClass('divhide');
      //   $('#polyselect').addClass('divhide');
      // } else {
      //   $('#selecttype').addClass('divhide');
      //   $('#markselect').addClass('divhide');
      // }
    },
    showPlist:function(){
      // if ($('#polyselect').hasClass('divhide')) {
      //   $('#selecttype').removeClass('divhide');
      //   $('#markselect').addClass('divhide');
      //   $('#polyselect').removeClass('divhide');
      // } else {
      //   $('#selecttype').addClass('divhide');
      //   $('#polyselect').addClass('divhide');
      // }
    },
    setMode:function(id){
      // switch (id) {
      //   case 1:
      //     currentSymbol=id;
      //     drawman.setOptions({markerOptions:{icon: '/assets/311.png'}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      //     break;
      //   case 2:
      //     currentSymbol=id;
      //     drawman.setOptions({markerOptions:{icon: '/assets/406.png'}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      //     break;
      //   case 3:
      //     currentSymbol=id;
      //     drawman.setOptions({markerOptions:{icon: '/assets/430.png'}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      //     break;
      //   case 4:
      //     currentSymbol=id;
      //     drawman.setOptions({markerOptions:{icon: '/assets/421.png'}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
      //     break;
      //   case 5:
      //     currentSymbol=id;
      //     drawman.setOptions({polylineOptions:{strokeColor: '#000000',strokeWeight:3, strokeOpacity:1}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
      //     break;
      //   case 6:
      //     currentSymbol=id;
      //     drawman.setOptions({polylineOptions:{strokeColor: '#ff8000',strokeWeight:3, strokeOpacity:1}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
      //     break;
      //   case 7:
      //     currentSymbol=id;
      //     drawman.setOptions({polygonOptions:{strokeColor: '#6699cc',strokeWeight:1, strokeOpacity:1,fillColor:'#6699cc',fillOpacity:0.7}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      //     break;
      //   case 8:
      //     currentSymbol=id;
      //     drawman.setOptions({polygonOptions:{strokeColor: '#ff0000',strokeWeight:1, strokeOpacity:1,fillColor:'#ff0000',fillOpacity:0.7}});
      //     drawman.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      //     break;
      // }
    },
    hideLists:function(){
      // $('#selecttype').addClass('divhide');
    },
    saveAnno:function() {
      // let sendData = {mode: 'anno'};
      // let exportA = [];
      // for (let i = 0; i < linepolymarker.length; i++) {
      //   let x;
      //   if (linepolymarker[i].getVisible()) {
      //     x = {typ: linepolymarker[i].typ, geom: ''};
      //     switch (x.typ) {
      //       case 1 :
      //       case 2 :
      //       case 3 :
      //       case 4 :
      //         x.geom = linepolymarker[i].getPosition().lat().toFixed(5) + ',' + linepolymarker[i].getPosition().lng().toFixed(5);
      //         break;
      //       case 5 :
      //       case 6 :
      //       case 7 :
      //       case 8 :
      //         x.geom = google.maps.geometry.encoding.encodePath(linepolymarker[i].getPath());
      //         break;
      //     }
      //     exportA.push(x);
      //   }
      // }
      // sendData.anno = JSON.stringify(exportA);
      // $.ajax({
      //   type: "POST", url: "/save911.php", dataType: 'json', data: sendData,
      //   error: function (xhr, error) {
      //   },
      //   success: function (json) {
      //     if (json.Status === 'OK') {
      //       latestAnnoId=json['annoid'];
      //     }
      //   }
      // });
    },
    refresh:function(){
      this.fetch911Data();
    },
    refreshOld:function(){
      let self=this ;
      apiClientSimple.get('./get911.php', {params: {'anno': this.latestAnnoId}})
        .then(response => {
          let json = response.data;
          if (json.Status === 'OK') {
            self.$parent.statusList=json.statuses;
            self.$parent.notesList=json.notes;
            if (json.data.length>0) {
            //   for (let i = 0; i < markers.length; i++) {
            //     markers[i].setVisible(false);
            //     markers[i].setMap(null);
            //   }
            //    markers = [];
               for (let i = 0; i < json.data.length; i++) {
            //     let pnt = new google.maps.LatLng(json.data[i].lat, json.data[i].lng);
                 let au=json.data[i].iresrc.replace('Active Units:','');
            //     let icon='/assets/fireems32.png';
                 let itype='fireems';
                 if (au.indexOf('A')>-1) {
                   // icon='/assets/ems32.png';
                   itype='ems';
                 }
                 if (au.indexOf('S')>-1){
                   // icon='/assets/fire32.png';
                   itype='fire';
                 }
                 if ((au.indexOf('A')>-1)&&(au.indexOf('S')>-1)) {
                   // icon='/assets/fireems32.png';
                   itype='fireems';
                 }
                 json.data[i].icontype=itype ;
            //     let mrk = new google.maps.Marker({
            //       map: iicepmap,
            //       position: pnt,
            //       icon: icon,
            //       draggable:false,
            //       data: json.data[i]
            //     });
            //     google.maps.event.addListener(mrk, 'click', mclicked);
            //     markers.push(mrk);
               }
            }
            self.$parent.incidentList=json.data.slice();
            let d = new Date();
            self.$parent.loadtime = d.toLocaleTimeString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            // if (json.annoid>self.latestAnnoId) {
            //   for (let i=0 ; i<linepolymarker.length ; i++) {
            //     google.maps.event.clearInstanceListeners(linepolymarker[i]);
            //     linepolymarker[i].setVisible(false);
            //     linepolymarker[i].setMap(null);
            //   }
            //   linepolymarker=[];
            //   let adata=JSON.parse(json.anno);
            //   for (let i=0 ; i<adata.length ; i++){
            //     let typ=adata[i].typ;
            //     let obj ;
            //     let geoms ;
            //     switch (typ) {
            //       case 1:
            //         geoms=adata[i].geom.split(',');
            //         obj=new google.maps.Marker({map:iicepmap,position:new google.maps.LatLng(parseFloat(geoms[0]),parseFloat(geoms[1])),icon: '/assets/311.png',draggable:false});
            //         break;
            //       case 2:
            //         geoms=adata[i].geom.split(',');
            //         obj=new google.maps.Marker({map:iicepmap,position:new google.maps.LatLng(parseFloat(geoms[0]),parseFloat(geoms[1])),icon: '/assets/406.png',draggable:false});
            //         break;
            //       case 3:
            //         geoms=adata[i].geom.split(',');
            //         obj=new google.maps.Marker({map:iicepmap,position:new google.maps.LatLng(parseFloat(geoms[0]),parseFloat(geoms[1])),icon: '/assets/430.png',draggable:false});
            //         break;
            //       case 4:
            //         geoms=adata[i].geom.split(',');
            //         obj=new google.maps.Marker({map:iicepmap,position:new google.maps.LatLng(parseFloat(geoms[0]),parseFloat(geoms[1])),icon: '/assets/421.png',draggable:false});
            //         break;
            //       case 5:
            //         geoms=google.maps.geometry.encoding.decodePath(adata[i].geom);
            //         obj=new google.maps.Polyline({map:iicepmap,path:geoms,strokeColor: '#000000',strokeWeight:3, strokeOpacity:1});
            //         break;
            //       case 6:
            //         geoms=google.maps.geometry.encoding.decodePath(adata[i].geom);
            //         obj=new google.maps.Polyline({map:iicepmap,path:geoms,strokeColor: '#ff8000',strokeWeight:3, strokeOpacity:1});
            //         break;
            //       case 7:
            //         geoms=google.maps.geometry.encoding.decodePath(adata[i].geom);
            //         obj=new google.maps.Polygon({map:iicepmap,path:geoms,strokeColor: '#6699cc',strokeWeight:1, strokeOpacity:1,fillColor:'#6699cc',fillOpacity:0.7});
            //         break;
            //       case 8:
            //         geoms=google.maps.geometry.encoding.decodePath(adata[i].geom);
            //         obj=new google.maps.Polygon({map:iicepmap,path:geoms,strokeColor: '#ff0000',strokeWeight:1, strokeOpacity:1,fillColor:'#ff0000',fillOpacity:0.7});
            //         break;
            //     }
            //     obj.typ=typ ;
            //     google.maps.event.addListener(obj,'rightclick',function(event){
            //       this.setVisible(false);
            //       self.saveAnno();
            //     });
            //     linepolymarker.push(obj);
            //   }
            // }
          }
        })
    }
  },
  mounted() {
    this.refresh();
    if (iicepStore.data._911Interval!=='') {
      clearInterval(iicepStore.data._911Interval);
      iicepStore.data._911Interval = '';
    }
    iicepStore.data._911Interval = setInterval(this.refresh, 120000);
  }
}
</script>
<template>
    <v-container
        fluid
        class="fill-height pa-0"
    >
        <DragCol height="100%" width="100%" class="limitheight" slider-bg-color="#385F73">
          <template #left>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <incidents911
                    :incidentData="this.$parent.incidentList"
                ></incidents911>
              </template>
              <template #bottom>
                <notes911
                    :notesData="this.$parent.notesList">
                ></notes911>
              </template>
            </DragRow>
          </template>
          <template #right>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <div id="layout">
                  <map911>
                  </map911>
                </div>
              </template>
              <template #bottom>
                <status911
                    :statusData="this.$parent.statusList">
                ></status911>
              </template>
            </DragRow>
          </template>
        </DragCol>
    </v-container>
</template>
<style scoped>
#layout {
  display: flex;
  flex-direction: column;
  height: 100%; /* Viewport height */
  width: 100%;  /* Viewport width */
  margin:0;
}
.limitheight {
  /*max-height: calc(100vh - 96px);*/
  max-height: 100%;
}
</style>
<style>
.drager_left > div > .drager_row > .drager_top > div {
  overflow-y: scroll !important;
}
.drager_bottom > div {
  overflow-y: scroll !important;
}
</style>