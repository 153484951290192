<script>
import {getIap, saveIap} from "@/services.js";

export default {
  name: "hicsIap",
  props: {
    iapInc: String
  },
  data: () => ({
    formfields: {
      iapId: -1,
      iapIncName: '',
      iapOpPeriod: '',
      iapOpDate: '',
      iapOpFrom: '',
      iapOpTo: '',
      iapIncAddress: '',
      iapSitSummary: '',
      iapTeamComm: '',
      iapTeamPubInf: '',
      iapTeamLiaison: '',
      iapTeamSafety: '',
      iapTeamMed: '',
      iapTeamOps: '',
      iapTeamPlan: '',
      iapTeamLogist: '',
      iapTeamFin: '',
      iapHealthBrf: '',
      iapImtAdd: [
        {fld7a: '', fld7b: '', fld7c: '', fld7d: ''}
      ],
      iapName: '',
      iapDateTime: '',
      iapAgency: '',
      iapIncMCODE: '',
      iapIncId: '',
      iapFormRev: 0
    },
    canSave: true,
    dialog: false
  }),
  computed: {},
  methods: {
    async loadData () {
      let self = this;
      try {
        const data = await getIap(this.iapInc);
        console.log("data:",data);
        Object.keys(self.formfields).forEach(key=>{
          if (key!=='iapImtAdd') {
            self.formfields[key] = data[key];
          }
        });
        self.formfields.iapImtAdd=JSON.parse(data.iapImtAdd);
      } catch (err) {
        console.log(err);
      }
    },
    async saveForm() {
      this.formfields.iapFormRev=this.formfields.iapFormRev+1;
      const formData = new FormData();
      Object.keys(this.formfields).forEach(key=>{
        if (key!=='iapImtAdd') {
          formData.append(key,this.formfields[key]);
        }
      });
      formData.append('iapImtAdd',JSON.stringify(this.formfields.iapImtAdd));
      try {
        const data = await saveIap(formData);
        if (data.Status === 'OK') {
          this.dialog = false ;
        } else {
          alert ("Error occurred!");
        }
      } catch (error) {
        alert ("Error occurred!");
        console.log(error);
      }
    },
    doCancel() {
      this.dialog = false ;
    },
    createNewEntry_7() {
      this.formfields.iapImtAdd.push({fld7a: '', fld7b: '', fld7c: '', fld7d: ''});
    }
  },
  mounted: function() {
    let self=this;
    self.loadData();
  }
}
</script>

<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      theme="light"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          prepend-icon="mdi-pen"
          v-bind="props"
          variant="outlined"
      >IAP QUICK FORM
      </v-btn>
    </template>
    <v-card>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="10">
          <v-card class="elevation-12">
            <v-toolbar class="text-center" color="primary" dark flat>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                INCIDENT ACTION PLAN (IAP) QUICK START<br/>
                COMBINED 201—202—203—204—215A
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col class="px-1" cols="3">
                    <v-text-field
                        v-model="formfields.iapIncName"
                        autocomplete='new-password'
                        hint="Incident Name (Select Type)"
                        persistent-hint
                        placeholder="Incident Name (Select Type)"
                    >
                      <v-chip
                          slot="prepend"
                      >
                        1.
                      </v-chip>
                    </v-text-field>
                  </v-col>
                  <v-col class="px-1" cols="3">
                    <v-text-field
                        v-model="formfields.iapOpPeriod"
                        autocomplete='new-password'
                        hint="Operational Period (#)"
                        persistent-hint
                        placeholder="Operational Period (#)"
                    >
                      <v-chip
                          slot="prepend"
                      >
                        2.
                      </v-chip>
                    </v-text-field>
                  </v-col>
                  <v-col class="px-1" cols="2">
                    <v-text-field
                        v-model="formfields.iapOpDate"
                        autocomplete='new-password'
                        hint="Date:"
                        persistent-hint
                        placeholder="Date:"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="px-1" cols="2">
                    <v-text-field
                        v-model="formfields.iapOpFrom"
                        autocomplete='new-password'
                        hint="From:"
                        persistent-hint
                        placeholder="From:"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="px-1" cols="2">
                    <v-text-field
                        v-model="formfields.iapOpTo"
                        autocomplete='new-password'
                        hint="To:"
                        persistent-hint
                        placeholder="To:"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="px-1" cols="12">
                    <v-text-field
                        v-model="formfields.iapIncAddress"
                        autocomplete='new-password'
                        hint="Incident Address (Street/Intersection, City/Town, State, ZIP)"
                        persistent-hint
                        placeholder="Incident Address (Street/Intersection, City/Town, State, ZIP)"
                    >
                      <v-chip
                          slot="prepend"
                      >
                        3.
                      </v-chip>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="px-1" cols="12">
                    <v-textarea
                        v-model="formfields.iapSitSummary"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="Situation summary"
                        outlined
                        placeholder="Situation summary"
                    >
                      <v-chip
                          slot="prepend"
                      >
                        4.
                      </v-chip>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left" cols="12">
                    <p>
                      <v-chip class="mr-2">5.</v-chip>
                      Current Incident Management Team (fill in additional positions as necessary)
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamComm"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Incident Commander"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamPubInf"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Public Information Officer"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamLiaison"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Liaison Officer"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamSafety"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Safety Officer"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamMed"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Medical-Technical Specialists"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamOps"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Operations Section Officer"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamPlan"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Planning Section Chief"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamLogist"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Logistics Section Chief"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" lg="3" md="4" sm="6">
                    <v-text-field
                        v-model="formfields.iapTeamFin"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        flat
                        label="Finance/Administration Section Chief"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left" cols="12">
                    <p>
                      <v-chip class="mr-2">6</v-chip>
                      <span class="font-weight-bold">Health and Safety Briefing </span>Identify potential incident
                      health and safety hazards and develop necessary measures (remove hazard, provide personal
                      protective equipment, warn people of the hazard) to protect responders from those hazards.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        v-model="formfields.iapHealthBrf"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="Health and Safety Briefing"
                        outlined
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left" cols="12">
                    <p>
                      <v-chip class="mr-2">7.</v-chip>
                      Current Incident Management Team (fill in additional positions as necessary)
                    </p>
                  </v-col>
                </v-row>
                <v-row v-for="(fld,i) in formfields.iapImtAdd" :key="i">
                  <v-col>
                    <v-textarea
                        v-model="fld.fld7a"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="7a. Objectives"
                        outlined
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                        v-model="fld.fld7b"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="7b. Strategies - Tactics"
                        outlined
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                        v-model="fld.fld7c"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="7c. Resources Required"
                        outlined
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                        v-model="fld.fld7d"
                        autocomplete='new-password'
                        background-color="grey lighten-4"
                        color="blue-grey"
                        label="7d. Assigned To"
                        outlined
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col class="text-right">
                    <v-btn
                        v-show="formfields.iapImtAdd[formfields.iapImtAdd.length-1].fld7a!=='' || formfields.iapImtAdd[formfields.iapImtAdd.length-1].fld7b!=='' || formfields.iapImtAdd[formfields.iapImtAdd.length-1].fld7c!=='' || formfields.iapImtAdd[formfields.iapImtAdd.length-1].fld7d!==''"
                        fab
                        @click="createNewEntry_7"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <span>Prepared By:</span>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="formfields.iapName"
                        autocomplete='new-password'
                        hint="NAME"
                        persistent-hint
                        placeholder="NAME"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="formfields.iapDateTime"
                        autocomplete='new-password'
                        hint="DATE/TIME"
                        persistent-hint
                        placeholder="DATE/TIME"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        autocomplete='new-password'
                        disabled
                        hint="SIGNATURE"
                        persistent-hint
                        placeholder="SIGNATURE"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="formfields.iapAgency"
                        autocomplete='new-password'
                        hint="AGENCY"
                        persistent-hint
                        placeholder="AGENCY"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  @click="doCancel"
              >CANCEL
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  v-show="canSave"
                  color="primary"
                  @click="saveForm"
              >SUBMIT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>