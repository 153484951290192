<script>
import {mapMutations} from 'vuex';
import {getIncHistory, objectToFormData, saveData} from "@/services.js";
import hicsIap from "@/components/hics-iap.vue";

export default {
  components: {hicsIap},
  methods: {
    ...mapMutations(['updateIncident','setIncidentClosed','setIncidentAnno']),
    async fetchIncHistory(incId) {
      let self = this;
      const resp = await getIncHistory(incId);
      if (resp['inchistory']) {
        self.ihistory = resp['inchistory'];
      } else {
        self.ihistory = [];
      }
    },
    setEditIncidentId() {
      this.incidents.forEach(inc => {
        if (inc.id === this.eid) {
          this.itype = inc.itype;
          this.iaddress = inc.address;
          this.istatus = inc.statustext;
          this.idescript = inc.descript;
          this.punote = inc.punote;
          this.prnote = inc.prnote;
          this.mode1 = '';
          this.mode2 = '';
        }
      })
      this.ihistory=this.fetchIncHistory(this.eid);
      this.mode1 = '';
      this.mode2 = '';
    },
    async setClosed() {
      this.setIncidentClosed(this.eid);
      const params = {"mode":"changincstat","newstat":2, "id":this.eid, "iid":this.iid};
      await saveData(objectToFormData(params));
      this.dialog = false;
    },
    async saveIAnno() {
      const params = {
        "mode":"updnotes",
        "punote": this.punote,
        "prnote": this.prnote,
        "savemode" : this.mode1+this.mode2,
        "id":this.eid,
        "iid":this.iid
      };
      await saveData(objectToFormData(params));
      if (this.mode1 ==='A') {
        this.setIncidentAnno(this.id, this.mode1, this.punote);
      }
      if (this.mode2 ==='B') {
        this.setIncidentAnno(this.id, this.mode2, this.prnote);
      }
      this.dialog=false;
    },
  },
/*
mode: updnotes
punote: acc
prnote: bd
id: 150493
iid: I20230917018
savemode: A

mode: changincstat
id: 150493
newstat: 4
iid: I20230917018

mode: changincstat
id: 150493
newstat: 2
iid: I20230917018


* */
  data: () => ({
    dialog: false,
    iaddress: null,
    istatus: null,
    idescript: null,
    itype: null,
    punote:null,
    prnote:null,
    ihistory:[],
    mode1: '',
    mode2: ''
  }),
  props: {
    mode: String,
    eid: Number,
    iid: String,
  },
  computed: {
    incidents() {
      return this.$store.state.incidents;
    },
    incidentTypeList() {
      return this.$store.state.incident_types;
    },
    canSave() {
      let rslt = false;
      if ((this.mode1+this.mode2)>' ') {
        rslt = true;
      }
      return rslt;
    }
  }
}
</script>
<template>
    <v-dialog
        theme="light"
        v-model="dialog"
        persistent
        width="40vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-if="mode==='map'"
            icon="mdi-pen"
            variant="flat"
            v-bind="props"
            @click="setEditIncidentId"
        ></v-btn>
        <a
            v-if="mode==='db'"
            v-bind="props"
            @click="setEditIncidentId"
            class="text-blue-grey-darken-4">
          {{ iid }}
        </a>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">
          <span class="text-h5 ">Edit Incident {{iid}}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row class="pa-0 mb-4 mt-2 mr-2">
              <v-col class="d-flex justify-space-evenly">
                <v-btn
                    density="default"
                    variant="outlined"
                    @click="setClosed"
                > Resolve Incident
                </v-btn>
                <hics-iap :iap-inc="iid"></hics-iap>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    v-model="itype"
                    :items="incidentTypeList"
                    density="compact"
                    item-title="l"
                    item-value="v"
                    label="Incident type"
                    variant="outlined"
                    readonly
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    v-model="iaddress"
                    append-inner-icon="mdi-magnify"
                    density="compact"
                    label="Incident Address"
                    readonly
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    density="compact"
                    label="Status"
                    variant="outlined"
                    v-model="istatus"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-textarea
                    auto-grow
                    density="compact"
                    label="Incident description..."
                    rows="3"
                    variant="outlined"
                    v-model="idescript"
                    readonly
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    density="compact"
                    label="Public note"
                    variant="outlined"
                    v-model="punote"
                    @change="mode1='A'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    density="compact"
                    label="Private note"
                    variant="outlined"
                    v-model="prnote"
                    @change="mode2='B'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-table v-if="ihistory.length>0">
              <thead>
              <tr>
                <th class="text-left">
                  Date/Time
                </th>
                <th class="text-left">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(ih, i) in ihistory" :key="i" >
                <td> {{ih.timestamp}} </td>
                <td> {{ih.action}} </td>
              </tr>
              </tbody>
            </v-table>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-0 d-flex justify-space-between">
          <v-btn
              class="mb-3 ml-2 mr-3"
              size="default"
              variant="outlined"
              @click="dialog = false"
          > Close
          </v-btn>
          <v-btn
              class="mb-3 ml-3 mr-2"
              size="default"
              variant="outlined"
              :disabled = "!canSave"
              @click="saveIAnno"
          > Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style>

</style>