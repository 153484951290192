<script>
import event from '@/components/eventDbEvent.vue'
import documents from "@/components/eventDbDocuments.vue";
import resrequest from '@/components/eventDbResreq.vue'
import incident from '@/components/eventDbIncidents.vue'
import aforms from '@/components/eventDbActform.vue'
import quadmapcomp from '@/components/eventDbMapview.vue'
import {DragCol, DragRow, Resize} from "vue-resizer";
import {apiClientSimple} from "@/services.js";
import iicepStore from "@/iicepStore.js";

export default {
  name: 'eventdb',
  data:function(){
    return {
      revisionList : [],
      eventDescr :{},
      annoList: [],
      eocafList:[],
      loadtime :'454545',
      dialog: false,
    }
  },
  components: {
    DragRow, DragCol,Resize,
    aforms, event, documents, quadmapcomp, incident, resrequest
  },
  methods: {
    refresh:function(){
      let self = this ;
      apiClientSimple.get('./getEDbData.php', {params: {'edb_event': this.$store.state._ICS214_eventId}})
          .then(response => {
            if (response.data.Status==='OK') {
              let json = response.data;
              self.revisionList=[];
              if (json.sitreps.length>0) {
                for (let i=0 ; i<json.sitreps.length ; i++) {
                  self.revisionList.push({
                    revId:json.sitreps[i].revId,
                    fid:json.sitreps[i].fid,
                    icsfile:json.sitreps[i].icsfile,
                    user:json.sitreps[i].user,
                    userid:json.sitreps[i].userid,
                    sitreptime:json.sitreps[i].sitreptime,
                    email:json.sitreps[i].email,
                    icsmsg:json.sitreps[i].icsmsg,
                    icsin:json.sitreps[i].icsin,
                    icsout:json.sitreps[i].icsout,
                    icspriority:json.sitreps[i].icspriority,
                    icsimmediate:json.sitreps[i].icsimmediate
                  });
                }
                self.revisionList.sort(function(a,b){
                  return (b.revId-a.revId);
                });
                this.$store.commit('set_edb_evRevisionList', self.revisionList);
                if (json.events) {
                  self.eventDescr= {
                    name: json.events[0].ename,
                    eventstatus: json.events[0].eventstatus,
                    eventstatusnum: json.events[0].eventstatusnum,
                    condition: json.events[0].condition,
                    prognosisnum:json.events[0].prognosis,
                    prognosis:json.events[0].prognosistxt,
                    impact:json.events[0].impacttxt,
                    impactnum:json.events[0].impact
                  };
                  this.$store.commit('set_edb_evDescription', self.eventDescr);
                }
              }
              if (json.annos) {
                self.annoList=json.annos ;
              }
              if (json.incidents) {
                this.$store.commit('set_edb_incidents',json.incidents);
              }
              if (json.resreqs1) {
                this.$store.commit('set_edb_resreqs',json.resreqs1);
              }
              if (json.afs) {
                this.$store.commit('set_edb_afs',json.afs);
              }
              let d = new Date();
              self.loadtime = d.toLocaleTimeString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            }
          })
          .catch(error => {
            console.error('Token verification failed:', error);
          });
    }
  },
  mounted() {
    this.refresh();
    if (iicepStore.data.edbInterval!=='') {
      clearInterval(iicepStore.data.edbInterval);
      iicepStore.data.edbInterval = '';
    }
    iicepStore.data.edbInterval = setInterval(this.refresh, 120000);
  }
}
</script>
<template>
    <v-container
        fluid
        class="fill-height"
    >
        <DragCol height="100%" width="100%" class="limitheight" slider-bg-color="#385F73">
          <template #left>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <event/>
              </template>
              <template #bottom>
                <documents/>
              </template>
            </DragRow>
          </template>
          <template #right>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <div id="testtest">
                  <quadmapcomp/>
                </div>
              </template>
              <template #bottom>
                <div>
                  <incident/>
                </div>
                <div>
                  <resrequest/>
                </div>
                <div>
                  <aforms/>
                </div>
              </template>
            </DragRow>
          </template>
        </DragCol>
    </v-container>
</template>
<style scoped>
#testtest {
  height: 100%;
}
.limitheight {
  max-height: calc(100vh - 96px);
}
</style>
<style>
.drager_left > div > .drager_row > .drager_top > div {
  overflow-y: scroll !important;
}

.drager_bottom > div {
  overflow-y: scroll !important;
}
</style>