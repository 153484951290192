<script>
import {mapGetters, mapMutations} from "vuex";
import {uploadAttachment, createIncident, geocodeAddress} from "@/services.js";

export default {
  methods: {
    ...mapMutations(['setTempCoords']),
    goToIncidents: function () {
      let self = this;
      this.setTempCoords(null);
      self.$router.push("/oem/incidents")
    },
    async saveAndGo() {
      let fidx = -1;
      if (this.ipic && this.ipic.length > 0) {
        let frslt = await uploadAttachment(this.ipic[0]);
        console.log(JSON.stringify(frslt));
        fidx = frslt['ID'];
      }
      let incdata = {
        id: -1,
        iid: '',
        address: this.iaddress,
        subname: this.cperson,
        subphone: this.cphone,
        submail: this.cmail,
        descript: this.idescr,
        itype: this.itype,
        fileid: fidx,
        lat: 0,
        lng: 0
      };
      await createIncident(incdata);
    },
    searchAddress() {
      geocodeAddress(this.iaddress)
          .then(
              data => {
                let location = [];
                if (data['Status'] === 'OK') {
                  location = data.location;
                }
                this.setTempCoords(location);
              })
          .catch(
              error => {
                console.error(error)
              }
          );
    }
  },
  data: () => ({
    dialog: false,
    itype: '',
    iaddress: '',
    idescr: '',
    cperson: '',
    cmail: '',
    cphone: '',
    ipic: null,
    rlst: null
  }),
  mounted() {
  },
  computed: {
    ...mapGetters(['getIncidentTypes']),
    incidentTypeList() {
      return this.getIncidentTypes;
    },
    canSave() {
      return !!this.itype && !!this.iaddress && !!this.idescr && !!this.cperson;
    }
  }
}
</script>
<template>
  <v-card>
    <v-card-title class="text-center">
      <span class="text-h5">Create New Incident</span>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0">
        <v-row class="mt-1">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-autocomplete
                v-model="itype"
                :items="incidentTypeList"
                density="compact"
                item-title="l"
                item-value="v"
                label="Incident type"
                variant="outlined"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-text-field
                v-model="iaddress"
                append-inner-icon="mdi-magnify"
                density="compact"
                label="Incident Address"
                required
                variant="outlined"
                @change="searchAddress"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-text-field
                v-model="cperson"
                density="compact"
                label="Contact name"
                required
                variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-text-field
                v-model="cphone"
                density="compact"
                label="Contact phone"
                required
                variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-text-field
                v-model="cmail"
                density="compact"
                label="Contact E-mail"
                variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12"
          >
            <v-textarea
                v-model="idescr"
                auto-grow
                density="compact"
                label="Incident description..."
                required
                rows="5"
                variant="outlined"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col
              class="pa-0"
              cols="12">
            <v-file-input
                v-model="ipic"
                :multiple="false"
                append-inner-icon="mdi-camera"
                clearable
                density="compact"
                label="File input"
                prepend-icon=""
                variant="outlined"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
          class="mb-5 ml-3 "
          size="default"
          variant="outlined"
          width="47%"
          @click="goToIncidents"
      > Cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          :disabled="!canSave"
          class="mb-5 mr-3"
          size="default"
          variant="outlined"
          width="47%"
          @click="saveAndGo"
      > Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style>
</style>