<script>
import {mapGetters, mapMutations} from "vuex";
import {createShelter, geocodeAddress} from "@/services.js";

export default {
  methods: {
    ...mapMutations(['setTempCoords']),
    ...mapGetters(['getNewCenter','getTempCoords']),
    goToShelters: function () {
      let self = this;
      this.setTempCoords(null);
      self.$router.push("/oem/shelters")
    },
    async saveAndGo() {
      let ctemp = {
        'mode': this.modes[ctype],
        'descr': this.descr,
        'address': this.address,
        'status': this.sstatus,
        'capacity': this.capacity,
        'occup': this.occup,
        'cfunc': this.cfunc,
        'lat': this.getTempCoords()[1],
        'lng':this.getTempCoords()[0],
      };
      await createShelter(ctemp);
    },
    searchAddress() {
      geocodeAddress(this.iaddress)
          .then(
              data => {
                let location = [];
                if (data['Status'] === 'OK') {
                  location = data.location;
                }
                this.setTempCoords(location);
              })
          .catch(
              error => {
                console.error(error)
              }
          );
    }
  },
  computed: {
    ctype() {
      let ctemplate = this.getNewCenter();
      if (!!ctemplate) {
        ctemplate = {
          'type':'shelters'
        }
      }
      return ctemplate.type
    },
    cfunc() {
      return `${this.op_appr?'1':'0'}${this.genpwr?'1':'0'}${this.longterm?'1':'0'}${this.daytime?'1':'0'}`+
          `${this.kitchen?'1':'0'}${this.shower?'1':'0'}${this.pets?'1':'0'}` ;
    },
    canSave() {
      return !!this.address && !!this.descr;
    }

  },
  data: () => ({
    dialog: false,
    typedefs: {
      'shelters': 'Shelter',
      'decons': 'Decon Center',
      'recepts': 'Reception Center'
    },
    modes: {
      'shelters': 'addshelt',
      'decons': 'adddecon',
      'recepts': 'addrecept'
    },
    sstat: [{v: 0, l: "Not Set"}, {v: 1, l: "Closed"}, {v: 2, l: "Open"}],
    address: null,
    descr: null,
    capacity: null,
    occup: null,
    sstatus: null,
    op_appr: null,
    genpwr: null,
    longterm: null,
    daytime:null,
    kitchen:null,
    shower:null,
    pets:null,
  }),
}
</script>
<template>
      <v-card>
        <v-card-title class="text-center"> Create new {{ctype}}{{typedefs[ctype]}} </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-text-field
                  v-model="descr"
                  density="compact"
                  label="Shelter description"
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-text-field
                  v-model="address"
                  required
                  density="compact"
                  label="Shelter Address"
                  variant="outlined"
                  @change="searchAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="ctype==='shelters'">
            <v-col
                class="pa-0 mt-1"
                cols="12"
            >
              <span class="text-h7 mb-4 ml-4 font-weight-bold">Shelter information </span>
            </v-col>
          </v-row>
          <v-row v-if="ctype==='shelters'">
            <v-col cols="6" sm="6" md="4" lg="4" class="pa-0">
              <v-checkbox
                  density="compact"
                  v-model="op_appr"
              >
                <template v-slot:label>
                  <div class="text-body-2">Opening Approved</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3" class="pa-0">
              <v-checkbox
                  density="compact"
                  v-model="genpwr"
              >
                <template v-slot:label>
                  <div class="text-body-2">Genset Power</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="5" class="pa-0">
              <v-checkbox
                  density="compact"
                  v-model="longterm"
              >
                <template v-slot:label>
                  <div class="text-body-2">Long term</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4" class="pa-0">
              <v-checkbox
                  density="compact"
                  v-model="daytime"
              >
                <template v-slot:label>
                  <div class="text-body-2">Daytime</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3" class="pa-0">
              <v-checkbox
                  density="compact"
                  label="Kitchen"
                  v-model="kitchen"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="4" md="3" lg="3" class="pa-0">
              <v-checkbox
                  density="compact"
                  label="Showers"
                  v-model="shower"
              ></v-checkbox>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" class="pa-0">
              <v-checkbox
                  density="compact"
                  label="Pets"
                  v-model="pets"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col
                class="pa-0"
                cols="12"
            >
              <v-autocomplete
                  v-model="sstatus"
                  :items="sstat"
                  density="compact"
                  item-title="l"
                  item-value="v"
                  label="Status"
                  variant="outlined"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col
                class="pa-0 pr-1"
                cols="6"
            >
              <v-text-field
                  v-model="capacity"
                  density="compact"
                  label="Capacity"
                  required
                  variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col
                class="pa-0 pl-1"
                cols="6"
            >
              <v-text-field
                  v-model="occup"
                  density="compact"
                  label="Occupancy"
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
              class="mb-5 ml-3"
              variant="outlined"
              size="default"
              width="47%"
              @click="goToShelters"
          > Close </v-btn>
          <v-btn
              class="mb-5 mr-3"
              variant="outlined"
              size="default"
              width="47%"
              :disabled="!canSave"
              @click="saveAndGo"
          > Save </v-btn>
        </v-card-actions>
      </v-card>
</template>
<style>
</style>