<template>
  <v-dialog
      theme="light"
      v-model="visible"
      max-width="500"
  >
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text><div v-html="message"></div></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="confirm">OK</v-btn>
        <v-btn color="secondary" text @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open({ title, message }) {
      this.title = title;
      this.message = message;
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.visible = false;
      if (this.resolve) this.resolve(true);
    },
    cancel() {
      this.visible = false;
      if (this.reject) this.reject(false);
    },
  },
};
</script>
