<script>
import {getICS214roster} from "@/services.js";

export default {
  components: {},
  methods: {
    async getRoster() {
      let data = await getICS214roster(this.selectedEvent);
      if (data && data.dutylog && data.dutylog.length > 0) {
         this.roster = [...data.dutylog];
         console.log('roster:',this.roster);
      }
    }
  },
  props: {
    eid:Number,
    mode: String
  },
  data() {
    return {
      dialog: false,
      eventName: 'TestEvent',
      selectedEvent: null,
      roster: [],
    }
  },
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name === 'xs') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'sm') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'md') {
        return '75vw';
      } else if (this.$vuetify.display.name === 'lg') {
        return '53vw';
      } else {
        return '45vw';
      }
    },
    eventList() {
      const evx = [];
      this.$store.state.events.forEach( ev => {
        if (ev['status']===1 || ev['status']===2) {
          evx.push({'l': ev['ename'], 'v': ev['id']});
        }
      })
      return evx;
    },
    userName() {
      return this.$store.state.loggedUserObj.FIRST_NAME + ' ' + this.$store.state.loggedUserObj.LAST_NAME
    }
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      :width="dlgwid"
  >
    <template v-slot:activator="{ props }">
      <v-btn v-if="mode==='btn'"
          density="comfortable"
          v-bind="props"
          variant="outlined"
      > Duty Roster
      </v-btn>
      <v-btn v-if="mode==='icon'"
             density="comfortable"
             v-bind="props"
             variant="flat"
             size="small"
             icon="mdi-clipboard-text"
      >
      </v-btn>
    </template>
    <v-card theme="light">
      <v-card-title class="text-left ma-3">
        <v-autocomplete
            v-model="selectedEvent"
            density="compact"
            hide-details="true"
            label="Event"
            variant="outlined"
            :items="eventList"
            item-title="l"
            item-value="v"
            @update:model-value="getRoster"
        >
        </v-autocomplete>
      </v-card-title>
      <v-card-text>
          <v-table>
            <thead>
            <tr>
              <th class="text-left px-1">User name</th>
              <th class="text-left px-1">Email</th>
              <th class="text-left px-1">Position</th>
              <th class="text-left px-1">Agency</th>
              <th class="text-left px-1">Start</th>
              <th class="text-left px-1">End</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="ul in roster">
              <td class="px-1">{{ul.uname}}</td>
              <td class="px-1">{{ul.uemail}}</td>
              <td class="px-1">{{ul.uposit}}</td>
              <td class="px-1">{{ul.uagency}}</td>
              <td class="px-1">{{ul.sdate}}</td>
              <td class="px-1">{{ul.edate}}</td>
              <td class="px-1">
                <v-btn
                    class="ma-0 pa-0"
                    density="compact"
                    size="small"
                    icon="mdi-file-multiple"
                    variant="flat">
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
            class="ma-2"
            size="large"
            variant="outlined"
            density="compact"
            @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
</style>