<script setup>
import mapwin from "@/components/mmap.vue";
import "mapbox-gl/dist/mapbox-gl.css"
</script>

<template>
  <v-layout>
    <div id="layout">
      <mapwin/>
    </div>
  </v-layout>
</template>
<script>
export default {
  components: {
    mapwin
  },
  name: 'mapview',
  props: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
  }
}
</script>

<style scoped>
#layout {
  flex: 1;
  display: flex;
  position: relative;
  width: 100vw;
  height: calc(100vh - 64px - 56px);
  margin-bottom: 0;
}
</style>