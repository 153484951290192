<script>
import iicepStore from "@/iicepStore.js";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {annoIcons911, incIcons911} from '@/utils.js';
import {mapState} from "vuex";
import {map911Config} from "@/map911config.js";
import {objectToFormData, save911} from "@/services.js";
mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ';

class MapButton {
  constructor(onClick, img, id) {
    this.onClick = onClick;
    this.imgurl = img;
    this.btnid = id;
  }
  setIcon(img) {
    this.imgurl = img;
    const buttonImg = document.querySelector(`#${this.btnid} img`);
    if (buttonImg) {
      buttonImg.src = img;
    }
  }
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    const button = document.createElement('button');
    button.id = this.btnid;
    const buttonImg = document.createElement('img');
    buttonImg.src = this.imgurl;
    button.appendChild(buttonImg);
    button.onclick = this.onClick;
    this.container.appendChild(button);
    return this.container;
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

class MapButtonArea {
  constructor() {
  }
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group custom-control-911';
    this.toggleButton = document.createElement('button');
    this.toggleButton.className = 'icon-button-911';
    this.toggleButton.id = '_911_edit';
    const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svgIcon.setAttribute('width', '20');
    svgIcon.setAttribute('height', '20');
    svgIcon.setAttribute('viewBox', '0 0 24 24');
    svgIcon.setAttribute('fill', 'none');
    svgIcon.setAttribute('stroke', 'currentColor');
    svgIcon.setAttribute('stroke-width', '2');
    svgIcon.setAttribute('stroke-linecap', 'round');
    svgIcon.setAttribute('stroke-linejoin', 'round');
    const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path1.setAttribute('d', 'M12 20h9');
    svgIcon.appendChild(path1);
    const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path2.setAttribute('d', 'M16.5 3a2.121 2.121 0 0 1 3 0l1.5 1.5a2.121 2.121 0 0 1 0 3L7 21H3v-4L16.5 3z');
    svgIcon.appendChild(path2);
    this.toggleButton.appendChild(svgIcon);
    this.toggleButton.onclick = () => this.toggleVisibility();
    this.contentDiv = document.createElement('div');
    this.contentDiv.style.display = 'none';
    this.contentDiv.innerHTML = `
      <div class="row-911">
        <button id="_911_cl_line" onclick="setMode('_911_cl_line')" class="draw_line"><img src="./assets/road_line_29.png" alt="Closure Line"/></button>
        <span>Road Closure</span>
        <button id="_911_cl_pnt" onclick="setMode('_911_cl_pnt')" class="draw_point"><img src="./assets/311.png" alt="Closure Pin" /></button>
      </div>
      <div class="row-911">
        <button id="_911_dt_line" onclick="setMode('_911_dt_line')" class="draw_line"><img src="./assets/detour_line_29.png" alt="Detour Line" /></button>
        <span>Detour</span>
        <button id="_911_dt_pnt" onclick="setMode('_911_dt_pnt')" class="draw_point"><img src="./assets/406.png" alt="Detour Pin" /></button>
      </div>
      <div class="row-911">
        <button id="_911_fl_line" onclick="setMode('_911_fl_line')" class="draw_line"><img src="./assets/flood_line_29.png" alt="Flood Line" /></button>
        <span>Flooded Area</span>
        <button id="_911_fl_pnt" onclick="setMode('_911_fl_pnt')" class="draw_point"><img src="./assets/430.png" alt="Flood Pin" /></button>
      </div>
      <div class="row-911">
        <button id="_911_fire_line" onclick="setMode('_911_fire_line')" class="draw_line"><img src="./assets/fire_line_29.png" alt="Fire Line" /></button>
        <span>Major Fire</span>
        <button id="_911_fire_pnt" onclick="setMode('_911_fire_pnt')" class="draw_point"><img src="./assets/421.png" alt="Fire Pin" /></button>
      </div>

      <div class="row-911">
        <span>&nbsp;</span>
        <button class="icon-button-911" id="_911_delete">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M3 6h18" />
            <path d="M5 6v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6" />
            <path d="M9 10v8" />
            <path d="M15 10v8" />
            <path d="M4 6l1-4h14l1 4" />
          </svg>
        </button>
        <span>&nbsp;&nbsp;</span>
        <span>&nbsp;&nbsp;</span>
        <button class="icon-button-911" id="_911_save">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
            <polyline points="17 21 17 13 7 13 7 21" />
            <polyline points="7 3 7 8 15 8" />
            <line x1="9" y1="21" x2="15" y2="21" />
          </svg>
        </button>
        <button class="icon-button-911" id="_911_cancel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
        <span>&nbsp;</span>
      </div> `;
    this.container.appendChild(this.toggleButton);
    this.container.appendChild(this.contentDiv);
    this.deleteButton = this.contentDiv.querySelector('#_911_delete');
    this.saveButton = this.contentDiv.querySelector('#_911_save');
    this.cancelButton = this.contentDiv.querySelector('#_911_cancel');
    return this.container;
  }
  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.contentDiv.style.display = this.isVisible ? 'block' : 'none';
    this.contentDiv.style.height = this.isVisible ? 'auto' : '0px';
    this.toggleButton.style.display = this.isVisible ? 'none' : 'block';
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default {
  name: 'map911',
  data: () => ({
    map: null,
    resizeObserver: null,
    initComplete1: false,
    initComplete2: false,
    maplayers: [],
    current_map: 'satellite',
    btn_icons: {
      'street': './assets/satellite.png',
      'satellite': './assets/street.png'
    },
    mapTypeControl: null,
    incident_pins: [],
    sourcedefs: {
      incidents911: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      anno_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
    },
    layerdefs: [
      {
        id: 'inc_points',
        type: 'symbol',
        source: 'incidents911',
        paint: {
          'icon-opacity': 1
        },
        layout: {
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
          'icon-ignore-placement': true,
          'icon-image': ['get','icontype'],
          'visibility': 'visible'
        }
      },
      {
        id: 'anno_points',
        type: 'symbol',
        source: 'anno_ftrs_src',
        filter: ['==', '$type', 'Point'],
        paint: {
          'icon-opacity': 1
        },
        layout: {
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
          'icon-ignore-placement': true,
          'icon-image': ['get','icon'],
          'visibility': 'visible'
        }
      },
      {
        id: 'anno_lines',
        type: 'line',
        source: 'anno_ftrs_src',
        filter: ["all", ["==", "$type", "LineString"]],
        paint: {
          "line-color": ["coalesce", ["get", "lineColor"], "#AAAAAA"],
          'line-width': 4
        }
      }
    ],
    editWidget: null,
    selectedGeom: '',
    selectedType: '',
  }),
  computed: {
    incdata() {
      return this.$store.state._911_incidentGeom;
    },
    annodata() {
      return this.$store.state._911_annoData;
    },
    initComplete() {
      return this.initComplete1 && this.initComplete2;
    }
  },
  watch: {
    incdata (newval) {
      this.reloadIncidents(newval);
    },
    annodata (newval) {
      this.reloadAnno(newval);
    },
    selectedGeom (newval) {
      switch (newval) {
        case 'LineString':
          this.draw.changeMode('draw_line_string');
          break;
        case 'Point':
          this.draw.changeMode('draw_point');
          break;
        case '':
          this.draw.changeMode('simple_select');
          break;
      }
    }
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.map = map;
    this.current_map = map911Config.initialMode;
    const Draw = new MapboxDraw({
      defaultMode: "simple_select",
      userProperties: true,
      displayControlsDefault: false,
      styles: [
        {
          'id': 'highlight-active-points',
          'type': 'symbol',
          'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'true']],
          'paint': {
            'icon-opacity': 1
          },
          'layout': {
            'icon-allow-overlap': true,
            'icon-anchor': 'center',
            'icon-ignore-placement': true,
            'icon-size': 1.2,
            'icon-image': ['get','user_icon']
          }
        },
        {
          'id': 'points-are-icons',
          'type': 'symbol',
          'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'false']],
          'paint': {
            'icon-opacity': 1
          },
          'layout': {
            'icon-allow-overlap': true,
            'icon-anchor': 'center',
            'icon-ignore-placement': true,
            'icon-image': ['get','user_icon']
          }
        },
        {
          "id": "gl-draw-line",
          "type": "line",
          "filter": ["all", ["==", "$type", "LineString"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": ["coalesce", ["get", "user_lineColor"], "#AAAAAA"],
            "line-dasharray": [3, 2],
            "line-width": 3
          }
        },
        {
          "id": "gl-draw-line-and-line-vertex-halo",
          "type": "circle",
          "filter": ["all",
                      ["==", "meta", "vertex"],
                      ['==', 'active', 'false'],
                      ["==", "$type", "Point"]],
          "paint": {
            "circle-radius": 5,
            "circle-color": "#D20C0C"
          }
        },
        {
          "id": "gl-draw-line-and-line-vertex",
          "type": "circle",
          "filter": ["all",
                      ["==", "meta", "vertex"],
                      ['==', 'active', 'false'],
                      ["==", "$type", "Point"]],
          "paint": {
            "circle-radius": 3,
            "circle-color": "#FF0",
          }
        },
        {
          "id": "gl-draw-line-and-line-vertex-halo-active",
          "type": "circle",
          "filter": ["all",
            ["==", "meta", "vertex"],
            ['==', 'active', 'true'],
            ["==", "$type", "Point"]],
          "paint": {
            "circle-radius": 7,
            "circle-color": "#D20C0C"
          }
        },
        {
          "id": "gl-draw-line-and-line-vertex-active",
          "type": "circle",
          "filter": ["all",
            ["==", "meta", "vertex"],
            ['==', 'active', 'true'],
            ["==", "$type", "Point"]],
          "paint": {
            "circle-radius": 5,
            "circle-color": "#FF0",
          }
        },
        {
          'id': 'gl-draw-line-midpoint',
          'type': 'circle',
          'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
          'paint': {
            'circle-radius': 3,
            'circle-color': '#820C0C'
          }
        },
      ],
    });
    this.draw = Draw;
    map.on('load', () => {
      try {
        map911Config.iicepLayers.forEach(lr => {
          let lstate = lr.state ? 'visible' : 'none';
          if (lr.type === 'layer') {
            map.setLayoutProperty(lr.id, 'visibility', lstate)
          }
          if (lr.type === 'group') {
            lr.members.forEach(mem => {
              map.setLayoutProperty(mem, 'visibility', lstate);
            })
          }
        });
        this.setMapMode(this.current_map);
        Object.keys(this.sourcedefs).forEach(k => {
          map.addSource(k, this.sourcedefs[k]);
        });
        this.layerdefs.forEach(lr => {
          map.addLayer(lr);
        })
        this.initComplete1 = true;
        this.addMarkerIcons().then(() => {
          this.initComplete2 = true;
          this.reloadAnno(this.annodata);
          this.reloadIncidents(this.incdata);
        });
      } catch (e) {
        console.log(e);
      }
    });
    map.on('draw.create', (e) => {
      const featureId = e.features[0].id;
      console.log("Created featureId--", featureId);
      if (this.selectedGeom === 'Point') {
        this.draw.setFeatureProperty(featureId, 'icon', this.selectedType);
        this.resetDrawButtons();
      }
      if (this.selectedGeom === 'LineString') {
        this.draw.setFeatureProperty(featureId, 'lineColor', this.selectedType);
      }
    });
    this.mapTypeControl = new MapButton(this.toggleMapMode, './assets/street.png', '_911_map_type');
    map.addControl(this.mapTypeControl, 'top-right');
    this.editWidget = new MapButtonArea();
    this.map.addControl(Draw);
    this.map.addControl(this.editWidget, 'top-right');
    this.editWidget.toggleButton.onclick = () => this.startEdits();
    this.editWidget.saveButton.onclick = () => this.saveEdits();
    this.editWidget.cancelButton.onclick = () => this.cancelEdits();
    this.editWidget.deleteButton.onclick = () => this.deleteSelected();
    this.selectedType = '';
    this.selectedGeom = '';
    const linebuttons = this.editWidget.contentDiv.querySelectorAll('.draw_line');
    const pointbuttons = this.editWidget.contentDiv.querySelectorAll('.draw_point');
    linebuttons.forEach(button => {
      button.onclick = () => {
        const btnid = button.id.replace('_911_', '');
        switch (btnid) {
          case 'cl_line':
            this.selectedType='#212629';
            break;
          case 'dt_line':
            this.selectedType='#ce6725';
            break;
          case 'fl_line':
            this.selectedType='#16b6ef';
            break;
          case 'fire_line':
            this.selectedType='#ef3516';
            break;
        }
        this.selectedGeom = 'LineString';
        this.resetDrawButtons();
        this.draw.changeMode('draw_line_string');
        button.classList.add('button-highlight-911');
      }
    });
    pointbuttons.forEach(button => {
      button.onclick = () => {
        const btnid = button.id.replace('_911_', '');
        switch (btnid) {
          case 'cl_pnt':
            this.selectedType='im311';
            break;
          case 'dt_pnt':
            this.selectedType='im406';
            break;
          case 'fl_pnt':
            this.selectedType='im430';
            break;
          case 'fire_pnt':
            this.selectedType='im421';
            break;
        }
        this.selectedGeom = 'Point';
        this.editWidget.contentDiv.querySelectorAll('.draw_line').forEach(button => {
          button.classList.remove('button-highlight-911');
        });
        this.editWidget.contentDiv.querySelectorAll('.draw_point').forEach(button => {
          button.classList.remove('button-highlight-911');
        });
        this.draw.changeMode('draw_point');
        button.classList.add('button-highlight-911');
      }
    })

    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.resizeObserver.observe(this.$refs.mapContainer);
    window.addEventListener('resize', this.handleWindowResize);
    iicepStore.data.map911Ref = map;
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    resetDrawButtons() {
      this.editWidget.contentDiv.querySelectorAll('.draw_line').forEach(button => {
        button.classList.remove('button-highlight-911');
      });
      this.editWidget.contentDiv.querySelectorAll('.draw_point').forEach(button => {
        button.classList.remove('button-highlight-911');
      });
    },
    setMapMode: function(maptype) {
      Object.keys(map911Config.modeLayersList).forEach( modekey => {
        map911Config.modeLayersList[modekey].forEach(lr => {
          this.map.setLayoutProperty(lr, 'visibility', (modekey === maptype) ? 'visible':'none' )
        })
      })
      this.mapTypeControl.setIcon(this.btn_icons[maptype]);
    },
    toggleMapMode: function() {
      if (this.current_map==='satellite') {
        this.setMapMode('street');
        this.current_map= 'street';
      } else if (this.current_map==='street') {
        this.setMapMode('satellite');
        this.current_map= 'satellite';
      }
    },
    // createEvents(lname) {
    //   this.map.on('click', lname, (e) => {
    //     const coordinates = e.features[0].geometry.coordinates.slice();
    //     let html = '<table>';
    //     Object.keys(e.features[0].properties).forEach(k => {
    //       html = html + `<tr><td>${k}</td><td>${e.features[0].properties[k]}</td></tr>`;
    //     })
    //     html += '</table>';
    //     while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    //       coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    //     }
    //     new mapboxgl.Popup()
    //         .setLngLat(coordinates)
    //         .setHTML(html)
    //         .addTo(this.map);
    //   });
    //   this.map.on('mouseenter', lname, () => {
    //     this.map.getCanvas().style.cursor = 'pointer';
    //   });
    //   this.map.on('mouseleave', lname, () => {
    //     this.map.getCanvas().style.cursor = '';
    //   });
    // },
    handleWindowResize() {
      this.map.resize();
    },
    hideAnnos() {
      this.map.setLayoutProperty('anno_points', 'visibility', 'none');
      this.map.setLayoutProperty('anno_lines', 'visibility', 'none');
    },
    showAnnos() {
      this.map.setLayoutProperty('anno_points', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_lines', 'visibility', 'visible');
    },
    deleteSelected() {
      this.draw.trash();
    },
    startEdits() {
      this.editWidget.toggleVisibility();
      console.log(JSON.stringify(this.annodata));
      this.draw.set(this.annodata);
      const xx = this.draw.getAll();
      console.log(xx)
      this.draw.changeMode('simple_select');
      this.hideAnnos();
    },
    async saveEdits() {
      this.editWidget.toggleVisibility();
      const adata = this.draw.getAll();
      this.$store.commit('set_911_annoData', adata);
      let xdata = {mode: 'anno', anno: JSON.stringify(adata)};
      await save911(objectToFormData(xdata))
          .then(response => {
            console.log("Saved anno data");
          });
      this.draw.deleteAll();
      this.showAnnos();
    },
    cancelEdits() {
      this.editWidget.toggleVisibility();
      this.draw.deleteAll();
      this.showAnnos();
    },
    reloadAnno(anno_geojson) {
      if (this.initComplete) {
        this.map.getSource('anno_ftrs_src').setData(anno_geojson);
      } else {
        console.log('anno, not init');
      }
    },
    reloadIncidents(inc_geojson) {
      if (this.initComplete) {
        this.map.getSource('incidents911').setData(inc_geojson);
      } else {
        console.log('incidents, not init');
      }
    },
    addMarkerIcons() {
      let self = this;
      const imagePromises = map911Config.iconslist.map(irec => {
        return new Promise((resolve, reject) => {
          self.map.loadImage(`./assets/${irec.icon}`, (error, image) => {
            if (error) {
              reject(error);
            } else {
              const imgname = 'im' + irec.value;
              if (!self.map.hasImage(imgname)) {
                self.map.addImage(imgname, image);
              }
              resolve();
            }
          });
        });
      });
      return Promise.all(imagePromises);
    }
  },
}
</script>

<template>
  <div ref="mapContainer" class="map-container-911"></div>
</template>

<style>
.map-container-911 {
  flex: 1;
}
.custom-control-911 div {
  padding-top: 5px;
}
.custom-control-911 .row-911 {
  display: flex;
  align-items: center; /* Vertically aligns items */
  justify-content: space-between; /* Distributes items evenly */
  margin-bottom: 5px;
}
.custom-control-911 .row-911 span {
  flex: 1; /* Allows the span to use remaining space */
  text-align: center; /* Centers the text */
}
.custom-control-911 .row-911 button {
  margin-left: 5px;
  margin-right: 5px;
}
.icon-button-911 {
  width: 29px;
  height: 29px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-button-911 svg {
  width: auto; /* Ensures the SVG scales within the padding */
  height: auto;
  display: block;
  margin: auto;
}
.button-highlight-911 {
  border: 3px solid blue !important; /* Example thicker border */
}
</style>