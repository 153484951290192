<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  methods: {
    goToHome: function () {
      let self = this;
      self.$router.push({name: "home"})
    },
    setEditEventId() {
      this.events.forEach(ev => {
        if (ev.id === this.eid) {
          this.ename = ev.ename;
          this.address = ev.address;
          this.evtype = ev.evtype;
          this.evtypename = ev.evtypename;
          this.prognosis = ev.prognosis;
          this.prognosistxt = ev.prognosistxt;
          this.impact = ev.impact;
          this.impacttxt = ev.impacttxt;
          this.eocstat = ev.eocstat;
          this.eocstattxt = ev.eocstattxt;
          this.estatus = ev.status;
          this.statustxt = ev.statustxt;
          this.AffJuri = ev.AffJuri.split(',').map(Number);
          this.dr = ev.dr;
          this.drcode = ev.drcode;
          this.ew = ev.ew || 0;
          this.ewcode = ev.ewcode || '' ;
          // console.log(JSON.parse(JSON.stringify(this.AffJuri)))
        }
      })
    },

  },
  data: () => ({
    ename: null,
    address: null,
    evtype:null,
    evtypename:null,
    prognosis:null,
    prognosistxt:null,
    impact:null,
    impacttxt:null,
    eocstat:null,
    eocstattxt:null,
    estatus:null,
    statustxt:null,
    dr:null,
    drcode:null,
    ew:null,
    ewcode:null,
    dialog: false,
    AffJuri: [],
  }),
  props: {
    eid: Number
  },
  computed: {
    ...mapGetters(['getEventTypes','getEventStatues']),
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '80vw';
      } else if (this.$vuetify.display.name==='md') {
        return '60vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '600px';
      } else {
        return '600px';
      }
    },
    events() {
      return this.$store.state.events;
    },
    event_types() {
      return this.getEventTypes;
    },
    event_statuses() {
      return this.getEventStatues;
    },
    munilist() {
      let mlist = [];
      this.$store.state.recipientsA.forEach( rec => {
        if (rec['group']!=='Neighbours') {
          mlist.push({'l': rec.Name, 'v': rec.Code});
        }
      })
      return mlist;
    },
    concatenatedSelectedItems() {
      return this.AffJuri
          .map((v) => this.munilist.find((item) => item.v === v).l)
          .join(', ');
    },

  }
}
</script>
<template>
    <v-dialog
        v-model="dialog"
        persistent
        :width="dlgwid"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-bind="props"
            icon="mdi-pencil"
            density="compact"
            variant="flat"
            size="small"
            @click="setEditEventId"
        ></v-btn>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">Edit Event "{{ename}}"</v-card-title>
        <v-card-text>
          <v-container class="pa-1">
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    variant="outlined"
                    label="Event name"
                    density="compact"
                    readonly
                    v-model="ename"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0 v-col-12"
              >
                <v-text-field
                    variant="outlined"
                    label="Event address"
                    append-inner-icon="mdi-magnify"
                    density="compact"
                    readonly
                    v-model="address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0 pr-1 v-col-12 v-col-sm-6"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="event_types"
                    item-title="l"
                    item-value="v"
                    label="Event type"
                    v-model="evtype"
                    readonly
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0 pl-1 v-col-12 v-col-sm-6"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="event_statuses"
                    item-value="v"
                    item-title="l"
                    label="Event Status"
                    v-model="estatus"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="munilist"
                    item-value="v"
                    item-title="l"
                    label="Affected Jurisdictions"
                    multiple
                    v-model="AffJuri"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index===0" class="custom-selection">
                      {{ concatenatedSelectedItems }}
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0 pr-1 v-col-8 v-col-sm-3"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="$yesno"
                    item-title="l"
                    item-value="v"
                    label="Dosimetry workers"
                    v-model="dr"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="py-0 px-1 v-col-4 v-col-sm-3"
                  >
                <v-text-field
                    v-if="dr===1"
                    variant="outlined"
                    label="Event code"
                    density="compact"
                    readonly
                    v-model="drcode"
                ></v-text-field>
              </v-col>
              <v-col
                  class="py-0 px-1 v-col-8 v-col-sm-3"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="$yesno"
                    item-title="l"
                    item-value="v"
                    label="Emergency workers"
                    v-model="ew"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="py-0 pl-1 v-col-4 v-col-sm-3"
                  >
                <v-text-field
                    v-if="ew===1"
                    variant="outlined"
                    label="Event code"
                    density="compact"
                    readonly
                    v-model="ewcode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  class="text-center pa-0">
                <v-btn
                    variant="outlined"
                    width="100%"
                    :href="'https://iicep.com/iapqsf.php?formid='+eid"
                    target="_blank"
                    rel="noopener noreferrer"
                >IAP Quick Start Form</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-3 ml-2 pa-0"
              variant="outlined"
              @click="dialog = false"
              width="30%"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-3 mr-2 pa-0"
              variant="outlined"
              @click="dialog=false"
              width="30%"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style scoped>
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>