<script>
import AddNewShelterDialog from "@/components/addNewShelter.vue";
import EditShelterDialog from "@/components/editShelterDialog.vue";
import DeleteEmergencyCenterDialog from "@/components/deleteEmergencyCenterDialog.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'shelters',
  props: {},
  data: () => ({
    sdialog: true,
    shelterType: 'shelters',
    layoutInline: true,
    typedefs: {
      'shelters': 'Shelter',
      'decons': 'Decon Center',
      'recepts': 'Reception Center'
    }
  }),
  components: {
    DeleteEmergencyCenterDialog,
    EditShelterDialog,
    AddNewShelterDialog
  },
  methods: {
    ...mapMutations(['createNewCenter']),
    createNew: function() {
      this.createNewCenter(this.shelterType);
      this.$router.push("/oem/newcenter");
    }
  },
  computed: {
    ...mapGetters(['getShelters', 'getDecons', 'getRecepts']),
    allCentersList() {
      const c1 = this.getShelters;
      const c2 = this.getDecons;
      const c3 = this.getRecepts;
      const cens = {
        'shelters': [],
        'decons': [],
        'recepts': []
      }
      if (!!c1) {
        cens['shelters'] = c1;
      }
      if (!!c2) {
        cens['decons'] = c2;
      }
      if (!!c3) {
        cens['recepts'] = c3;
      }
      return cens;
    }
  },
  watch: {},
  mounted() {
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      Emergency centers
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <v-radio-group
              v-model="shelterType"
              inline
          >
            <v-radio
                label="Shelters"
                value="shelters"
            ></v-radio>
            <v-radio
                label="Reception centers"
                value="recepts"
            ></v-radio>
            <v-radio
                label="Decon centers"
                value="decons"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-1 text-left">
          <v-btn
              prepend-icon="mdi-plus"
              variant="outlined"
              width="90%"
              @click="createNew"
          >
            Add New {{typedefs[shelterType]}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="comfortable">
            <thead>
            <tr>
              <th class="text-left">
                Center
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Status
              </th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="cen in allCentersList[shelterType]" :key="cen.id">
              <td>{{ cen['descr'] }}</td>
              <td>{{ typedefs[shelterType] }}</td>
              <td>{{ cen.statustxt }}</td>
              <td>
                <div class="d-flex justify-end align-center">
                  <div>
                    <v-btn
                        density="compact"
                        icon="mdi-pen"
                        variant="flat"
                    >
                      <editShelterDialog :cid="cen.id" :ctype="shelterType"/>
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                        density="compact"
                        icon="mdi-delete-outline"
                        variant="flat"
                    >
                      <deleteEmergency-center-dialog :cid="cen.id" :cname="cen['descr']"/>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>

</style>