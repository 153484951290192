<script>
import Dutylog from "@/components/eventDbDutylog.vue";
import Sitrepmsg from "@/components/eventDbSitrepmsg.vue";
import ActivityLog from "@/components/activityLog.vue";
export default {
  name: 'event',
  components: {
    ActivityLog,
    Sitrepmsg,
    Dutylog
  },
  methods: {
    sendSitrepMessage: function () {
      alert("Ha");
      console.log(this.msgInout);
      console.log(this.msgImm);
      console.log(this.msgPri);
    },
    openALog: function (uid) {
      let that = this;
      $.ajax({
        type: "GET", url: "/getUserLog.php", dataType: 'json', data: {uid: uid},
        error: function (xhr, error) {
        },
        success: function (json) {
          if (json.Status === 'OK') {
            that.icsroster = json.icsroster;
            that.dutylog = json.icsdutylog;
            that.showdutylog = true;
          }
        }
      });
    },
    closeALog: function () {
      this.showdutylog = false;
    },
    sendnewmsg: function () {
      this.shownewmsg = false;
    },
    cancelnewmsg: function () {
      this.shownewmsg = false;
    },
    showMsgDlg: function () {
      this.shownewmsg = true;
    }
  },
  data: () => ({
    return: {
      shownewmsg: false,
      showdutylog: false,
      icsroster: {},
      dutylog: [],
      msgInout: "",
      msgImm: "",
      msgPri: ""
    }
  }),
  computed: {
    ename() {
      return this.$store.state._edb_evDescription.name;
    },
    estatusclass() {
      return 'estatus' + this.$store.state._edb_evDescription.eventstatusnum;
    },
    estatus() {
      return this.$store.state._edb_evDescription.eventstatus;
    },
    prognosisclass() {
      return 'prognosis' + this.$store.state._edb_evDescription.prognosisnum.toString()
    },
    prognosis() {
      return this.$store.state._edb_evDescription.prognosis;
    },
    impactclass() {
      return 'impact' + this.$store.state._edb_evDescription.impactnum.toString();
    },
    impact() {
      return this.$store.state._edb_evDescription.impact;
    },
    condition() {
      return this.$store.state._edb_evDescription.condition;
    },
    messages() {
      return this.$store.state._edb_evRevisionList.map(
          rev => ({
            revId: rev.revId,
            sitreptime: rev.sitreptime,
            user: rev.user,
            userid: rev.userid,
            icsmsg: rev.icsmsg,
            fid: rev.fid,
            icsfile: rev.icsfile
          })
      );
    }
  }
}
</script>
<template>
  <div class="event">
    <h3 class="text-center font-weight-bold">Active Event </h3>
    <div class="d-flex justify-space-around">
      <div>
        <span class="normal0">Event:</span><span class="black0">{{ ename }}</span>
      </div>
      <div>
        <span class="normal0">Status:</span><strong><span v-bind:class="estatusclass">{{ estatus }}</span></strong>
      </div>
    </div>
    <div class="d-flex justify-space-around">
      <div>
        <span class="normal0">Prognosis:</span><strong><span v-bind:class="prognosisclass">{{ prognosis }}</span></strong>
        </div>
        <div>
          <span class="normal0">Impact:</span><strong><span v-bind:class="impactclass">{{impact}}</span></strong>
        </div>
        <div>
          <span class="normal0">Condition:</span><strong><span class="condition0">{{condition}}</span></strong>
        </div>
        <div>
          <v-btn @click="showMsgDlg" type="button" class="btn btn-secondary" >New Message</v-btn>
        </div>
      </div>
      <div class="det-top">
        <div class="message-list">
            <table class="tab">
                <tbody>
                <tr>
                    <th>Rev</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Message / Document</th>
                </tr>
                <tr v-for="entry in messages">
                    <td class="td2">
                        <span>{{entry.revId}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.sitreptime}}</span>
                    </td>
                    <td class="td2">
                        <span><a href='#' @click="openALog(entry.userid)">{{entry.user}}</a></span>
                    </td>
                    <td class="td2">
                        <span>{{entry.icsmsg}}</span><br />
                        <span><a v-bind:href="'./getFile.php?id='+entry.fid" target="_blank">{{entry.icsfile}}</a></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
<!--        <div class="msgpost">-->
<!--            <vue-modaltor-->
<!--                :visible="shownewmsg"-->
<!--                :bg-overlay="'transparent'"-->
<!--                :bg-panel = "'#eeeeee'"-->
<!--                :default-width="'800px'"-->
<!--                :show-close-button="false">-->
<!--              <div>-->
<!--                <sitrepmsg>-->
<!--                </sitrepmsg>-->
<!--              </div>-->
<!--            </vue-modaltor>-->
<!--        </div>-->
<!--        <div>-->
<!--            <vue-modaltor :visible="showdutylog" @hide="closeALog" :bg-overlay="'transparent'" :bg-panel = "'#cccccc'" :default-width="'950px'" >-->
<!--                <div>-->
<!--                    <dutylog-->
<!--                            :userics="icsroster"-->
<!--                            :actlog="dutylog">-->
<!--                    </dutylog>-->
<!--                </div>-->
<!--            </vue-modaltor>-->
<!--        </div>-->
    </div>
    </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.det-top {
    font-size: 16px;
}
.row {
    margin: 0;
}
.rdiv {
    float: right;
    font-size: 16px;
}
.ldiv {
    float: left;
    font-size: 16px;
}
.rdiv50 {
    float: right;
    width:48%;
    min-width:200px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    text-align: left;
}
.ldiv50 {
    float: left;
    padding: 0 10px 0 10px;
    font-size: 16px;
    width:48%;
    min-width:200px;
    text-align: left;
}
.event h3 {
  height: 25px;
}
.det-top {
    display: block;
}
ul {
  list-style-type: square;
  padding: 0;
}
a {
  color: #011efe;
}
.message-list {
    overflow-y: auto;
    text-align: left;
}
.details p {
    margin-top: 5px;
}
.xrow5 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 7px;
    text-align: left;
    clear: both;
    overflow: auto;
}
.xrow7 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    text-align: left;
    clear: both;
    overflow: auto;
}
.win_h2a {
    font: 30px Cambria, Arial, sans-serif;
    font-weight: bold;
    width: 95%;
    text-align: left;
    margin: auto;
    padding: 5px;
    letter-spacing: -1px;
    color: #C55C28;
}
.td2 {
    clear: both;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding : 5px 5px 5px 15px;
}
th {
    clear: both;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    background-color: #7c93c0;
    font-weight: bold;
    padding : 5px 5px 5px 15px;
}
.tab {
    width: 100%;
}
tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}
#win8b9 {
    height: 40px;
    width: 377px;
    float: left;
    display: inline-block;
    margin: 10px 0 0 10px;
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #FFFFFF;
    text-shadow: none;
    border-color: transparent;
    background-color: #BDBEC0;
}
#win8b10 {
    height: 40px;
    width: 377px;
    float: right;
    display: inline-block;
    margin: 10px 10px 0 0;
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #FFFFFF;
    text-shadow: none;
    border-color: transparent;
    background-color: #C55C28;
}
#win8b1 {
    width: 770px;
    height: 30px;
    color: #666666;
    text-shadow: none;
    border-color: #C8C6C6;
    background-color: #F2F2F3;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    font-family: Cambria, Georgia, "Times New Roman", serif;
    -webkit-appearance: none;
    padding: 0 0 0 0;
    outline: none;
    border-width: 1px;
    border-style: solid;
}
.msgpost {
    white-space: normal;
}
tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}
.lastrow {
    padding-bottom: 5px;
}

.estatus0,
.estatus1,
.estatus2,
.estatus3,
.estatus4 {
    text-align: center;
    font-size: 15px;
    padding: 10px;
    display: inline-block;
}
.estatus0 {
    background-color: #b0b0b0;
    color: #000000;
}
.estatus1 {
    background-color: #00b000;
    color: #f0f0f0;
}
.estatus2 {
    background-color: #b0b000;
    color: #000000;
}
.estatus3 {
    background-color: #000080;
    color: #f0f0f0;
}
.estatus4 {
    background-color: #b00000;
    color: #f0f0f0;
}

.normal0,
.black0,
.condition0,
.impact0,
.impact1,
.impact2,
.impact3,
.impact4,
.impact5,
.impact6 {
    text-align: center;
    font-size: 15px;
    padding: 10px;
    display: inline-block;
}

.black0 {
  font-weight: bold;
}
.impact0 {
    background-color: #b0b0b0;
    color: #000000;
}

.impact1 {
    background-color: #000000;
    color: #ffffff;
}

.impact2 {
    background-color: #ff0000;
    color: #ffff00;
}

.impact3 {
    background-color: #ffff00;
    color: #0000ff;
}

.impact4 {
    background-color: #00ff00;
    color: #ff00ff;
}

.impact5 {
    background-color: #404040;
    color: #ffffff;
}

.impact6 {
    background-color: #0000ff;
    color: #ffff00;
}

.prognosis0,
.prognosis1,
.prognosis2,
.prognosis3,
.prognosis4 {
    text-align: center;
    font-size: 15px;
    padding: 10px;
    display: inline-block;
}

.prognosis0 {
    background-color: #b0b0b0;
    color: #000000;
}

.prognosis1 {
    background-color: #ff0000;
    color: #ffff00;
}

.prognosis2 {
    background-color: #ffff00;
    color: #0000ff;
}

.prognosis3 {
    background-color: #00ff00;
    color: #ff00ff;
}

.prognosis4 {
    background-color: #606060;
    color: #ffffff;
}

</style>
