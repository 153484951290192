import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const auth = getAuth();

export const checkAuthState = () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async user => {
            if (user) {
                try {
                    const idToken = await user.getIdToken();
                    resolve({ user, idToken });
                } catch (error) {
                    reject(error);
                }
            } else {
                resolve(null);
            }
        }, reject);
    });
};

export const SignOutUser = () => {
    return new Promise((resolve, reject) => {
        signOut(auth).then(() => {
            resolve();
        }).catch(error => {
            reject(error);
        });
    });
};