<template>
    <div>
        <div id="win10body">
            <div class="xrow"><h2 class="xh2">Duty Roster/Activity Log (ICS 214)</h2></div>
            <div class="xrow"><h2 class="xh2" id="win10b1"></h2></div>
            <div class="xrow7">
                <div class="win7txt1">Start Operational Period:</div>
                <div class="txtinput">{{userics.sop}}</div>
                <div class="win7txt1">Position:</div>
                <div class="txtinput">{{userics.posit}}</div>
            </div>
            <div class="xrow7">
                <div class="win7txt1">End Operational Period:</div>
                <div class="txtinput">{{userics.eop}}</div>
                <div class="win7txt1">Agency:</div>
                <div class="txtinput">{{userics.uagency}}</div>
            </div>
            <div class="xrow7">
                <div class="win7txt1">Name:</div>
                <div class="txtinput">{{userics.uname}}</div>
                <div class="win7txt1">Logged In as:</div>
                <div class="txtinput">{{userics.umail}}</div>
            </div>
            <div class="xrow7">
                <div class="win7txt1">Relief Name:</div>
                <div class="txtinput">{{userics.rname}}</div>
                <div class="win7txt1">Relief Time:</div>
                <div class="txtinput">{{userics.rtime}}</div>
            </div>
            <div class="xrow7" v-if="userics.res1!==''">
                <div class="win6txt1">Resources assigned:</div>
                <div class="txtinputsm">{{userics.res1}}</div>
                <div class="win5txt1">ICS Position:</div>
                <div class="txtinputsm">{{userics.icsp1}}</div>
                <div class="win5txt1">Home Agency:</div>
                <div class="txtinputsm">{{userics.hage1}}</div>
            </div>
            <div class="xrow7" v-if="userics.res2!==''">
                <div class="win6txt1">Resources assigned:</div>
                <div class="txtinputsm">{{userics.res2}}</div>
                <div class="win5txt1">ICS Position:</div>
                <div class="txtinputsm">{{userics.icsp2}}</div>
                <div class="win5txt1">Home Agency:</div>
                <div class="txtinputsm">{{userics.hage2}}</div>
            </div>
            <div class="xrow7" v-if="userics.res3!==''">
                <div class="win6txt1">Resources assigned:</div>
                <div class="txtinputsm">{{userics.res3}}</div>
                <div class="win5txt1">ICS Position:</div>
                <div class="txtinputsm">{{userics.icsp3}}</div>
                <div class="win5txt1">Home Agency:</div>
                <div class="txtinputsm">{{userics.hage3}}</div>
            </div>
            <div class="xrow7">
                <table id="win10b17">
                    <tr>
                        <th class="win10h1">User Name</th>
                        <th class="win10h2">Rev</th>
                        <th class="win10h3">Time</th>
                        <th class="win10h4">In/Out</th>
                        <th class="win10h5">Priority</th>
                        <th class="win10h6">Message</th>
                        <th class="win10h7">Document</th>
                    </tr>
                    <tr v-for="entry in actlog">
                        <td class="win10h1">{{entry.uname}}</td>
                        <td class="win10h2">{{entry.revId}}</td>
                        <td class="win10h3">{{entry.mdatetime}}</td>
                        <td class="win10h4">{{parseInt(entry.in)?'IN':''}}{{parseInt(entry.out)?'OUT':''}}</td>
                        <td class="win10h5">{{parseInt(entry.prio)?'Pri':''}}{{(parseInt(entry.prio)+parseInt(entry.immed))===2?'/':''}}{{parseInt(entry.immed)?'Imm':''}}</td>
                        <td class="win10h6">{{entry.msg}}</td>
                        <td class="win10h7">{{entry.doc}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dutylog",
        props: {
            userics:Object,
            actlog:Array
        }
    }
</script>

<style scoped>
    #win10b17 {
        width: 100%;
        box-sizing: border-box;
        table-layout: fixed;
        font-size: 12px;
    }
    #win10b17 th {
        padding: 3px;
        color: #222222;
        background-color: #a6a6a6;
        text-align: center;
        box-sizing: border-box;
    }
    .win10h1 {
        width: 140px;
    }
    .win10h2 {
        width: 35px;
    }
    .win10h3 {
        width: 110px;
    }
    .win10h4 {
        width: 45px;
    }
    .win10h5 {
        width: 55px;
    }
    .win10h6 {
        width: 200px;
    }
    .win10h7 {
        word-wrap: break-word;
    }
    .win7txt1 {
        width: 200px;
        display: inline-block;
        text-align: left;
        padding-left: 15px;
        height: 30px;
        line-height: 30px;
        float: left;
        margin: 3px;
        font-size: 14px;
    }
    .win6txt1 {
        width: 140px;
        display: inline-block;
        text-align: left;
        padding-left: 15px;
        height: 30px;
        line-height: 30px;
        float: left;
        margin: 3px;
        font-size: 14px;
    }
    .win5txt1 {
        width: 100px;
        display: inline-block;
        text-align: left;
        padding-left: 15px;
        height: 30px;
        line-height: 30px;
        float: left;
        margin: 3px;
        font-size: 14px;
    }
    .txtinput {
        width: 243px;
        height: 30px;
        float: left;
        display: inline-block;
        box-sizing: border-box;
        min-height: 16px;
        min-width: 16px;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        font-size: 14px;
        padding: 0 3px 0 3px;
        line-height: 27px;
    }
    .txtinputsm {
        width: 180px;
        height: 30px;
        float: left;
        display: inline-block;
        box-sizing: border-box;
        min-height: 16px;
        min-width: 16px;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        font-size: 14px;
        padding: 0 3px 0 3px;
        line-height: 27px;
    }
    .xrow {
        display: block;
        width: 100%;
        padding: 10px 5px 10px 5px;
        overflow: auto;
        box-sizing: border-box;
        font-size: 12px;
    }
    .xrow7 {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        text-align: left;
        clear: both;
        overflow: auto;
    }
    .xh2 {
        font: 20px Cambria, Arial, sans-serif;
        font-weight: bold;
        width: 95%;
        text-align: left;
        padding: 5px;
        letter-spacing: -1px;
        color: #666666;
        margin: 0;
    }
</style>
