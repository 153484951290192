<script>
import { saveData} from "@/services.js";

export default {
  methods: {
    deleteSelectedUser: async function () {
      const fd = new FormData() ;
      fd.append("mode", 'deluser');
      fd.append('userid', this.uid);
      let result1 = await saveData(fd);
      if (!!result1['Status']) {
        if (result1['Status']==='OK') {
          this.$store.commit('deleteUser',result1['userid']);
          this.closeWin();
        }
      }
    },
    closeWin: function () {
      this.dialog = false;
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    uid: Number,
    uname: String,
    umail: String,
  },
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '70vw';
      } else if (this.$vuetify.display.name==='md') {
        return '40vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '30vw';
      } else {
        return '25vw';
      }
    },
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      :width="dlgwid"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          density="compact"
          icon="mdi mdi-delete-outline"
          v-bind="props"
          variant="flat"
      >
      </v-btn>
    </template>

    <v-card
        id="deletionDialogText"
        height="auto"
        >
      <v-card-text
          class="text-h6 text-center"
      >Do you want to delete user {{ uname }} ({{ umail }})?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            id="disagreeButton"
            color="red-darken-4"
            density="comfortable"
            variant="outlined"
            @click="deleteSelectedUser"
        >
          Yes
        </v-btn>
        <v-btn
            id="agreeButton"
            color="green-darken-4"
            density="comfortable"
            variant="outlined"
            @click="closeWin"
        >
          No
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
</style>