import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBtZVZPr0TNn0jvZ_Yfh9ZvQZaUCQm5VSc",
    authDomain: "iicep-e8fc5.firebaseapp.com",
    databaseURL: "https://iicep-e8fc5-default-rtdb.firebaseio.com",
    projectId: "iicep-e8fc5",
    storageBucket: "iicep-e8fc5.appspot.com",
    messagingSenderId: "935109385449",
    appId: "1:935109385449:web:ee4a82da7728c4ed46a478",
    measurementId: "G-P1G09RZ1BW"
};

initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
