<script>
import addNewIncidentDialog from "@/components/addNewIncidentDialog.vue";
import EditIncident from "@/components/editIncident.vue";
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'incidents',
  components: {EditIncident, addNewIncidentDialog},
  props: {},
  data: () => ({
    open: 'Open',
    incidentStatus: 'New',
    layoutInline: true
  }),
  methods: {
    ...mapMutations(['setIncidentOpen', 'setIncidentClosed', 'setIncidentRejected', 'setIncidentNew',
      'setIncidentForwarded', 'setIncidentPurged']),
    setOpen: function(id) {
      this.setIncidentOpen(id);
    },
    setClosed: function(id) {
      this.setIncidentClosed(id);
    },
    setRejected: function(id) {
      this.setIncidentRejected(id);
    },
    setNew: function(id) {
      this.setIncidentNew(id);
    },
    setPurged: function(id) {
      this.setIncidentPurged(id);
    },
    setForwarded: function(id) {
      this.setIncidentForwarded(id);
    },
    goToNewInc: function() {
      let self = this;
      self.$router.push("/oem/newincident");
    }
  },
  watch: {},
  mounted() {
  },
  computed: {
    ...mapGetters(['getIncidents']),
    incidentlist() {
      const ilist = [];
      this.getIncidents.forEach( iel => {
        if (iel.status<5) {
          ilist.push(iel);
        }
      })
      return ilist;
    }
  }
}
</script>
<template>
  <v-card
      variant="flat"
      color="white"
      min-width="500"
  >
    <v-card-title>
      Incidents
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
              density="default"
              variant="outlined"
              @click="goToNewInc"
          >ADD NEW INCIDENT
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="compact">
            <thead>
            <tr>
              <th class="text-left">
                Incident ID
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Status
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="incitem in incidentlist">
              <td>{{incitem['iid']}}</td>
              <td>{{incitem['itypename']}}</td>
              <td> {{incitem['statustext']}} </td>
              <td class="text-right pa-0">
                <v-btn
                    variant="flat"
                    title="Edit"
                    v-if="incitem.statustext==='Open/Accepted' "
                > <edit-incident mode="map" :eid="incitem.id" :iid="incitem.iid"/>
                </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='New' "
                    icon="mdi-check"
                    density="compact"
                    variant="flat"
                    @click="setOpen(incitem.id)"
                    title = "Accept"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='New' "
                    icon="mdi-cancel"
                    density="compact"
                    variant="flat"
                    @click="setRejected(incitem.id)"
                    title = "Reject"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='New' "
                    icon="mdi-share"
                    density="compact"
                    variant="flat"
                    title="Forward"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='Open/Accepted' "
                    icon="mdi-close-circle-outline"
                    density="compact"
                    variant="flat"
                    @click="setClosed(incitem.id)"
                    title="Close"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='Closed' "
                    icon="mdi-delete-forever"
                    density="compact"
                    variant="flat"
                    @click="setPurged(incitem.id)"
                    title="Purge"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='Closed' "
                    icon=" mdi-refresh"
                    density="compact"
                    variant="flat"
                    @click="setOpen(incitem.id)"
                    title="Reopen"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='Rejected' "
                    icon=" mdi-refresh"
                    density="compact"
                    variant="flat"
                    @click="setOpen(incitem.id)"
                    title="Reopen"
                > </v-btn>
                <v-btn
                    class="ma-1"
                    v-if="incitem.statustext==='Rejected' "
                    icon="mdi-delete"
                    density="compact"
                    variant="flat"
                    @click="setClosed(incitem.id)"
                    title="Close"
                > </v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
</style>