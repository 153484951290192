export default {
   data: {
       mapRef: null,
       edbMapRef: null,
       mapDrawRef: null,
       map911Ref: null,
       layers: [
           { 'label':'ERPA', 'id':'erpa-ll-1oikwz', 'state': true, 'type':'layer'},
           { 'label':'Dams(Salem)', 'id':'dams1-66ktwt', 'state': false, 'type':'layer'},
           { 'label':'Dams(NJ)', 'id':'dams2-bcw6vq', 'state': false, 'type':'layer'},
           { 'label':'Sirens', 'id':'sirens-salem', 'state': false, 'type':'layer'},
           // { 'label':'Flood Areas', 'id':'flood', 'state': true, 'type':'layer'},
           { 'label':'Congregate Care', 'id':'congregate-care', 'state': false, 'type':'layer'},
           { 'label':'Bus Routes', 'id':'bus-routes', 'state': false, 'type':'group', 'members':['bus-routes-lines', 'bus-routes-arrows']}
       ],
       edblayers: [
           { 'label':'ERPA', 'id':'erpa-ll-1oikwz', 'state': true, 'type':'layer'},
           { 'label':'Dams(Salem)', 'id':'dams1-66ktwt', 'state': false, 'type':'layer'},
           { 'label':'Dams(NJ)', 'id':'dams2-bcw6vq', 'state': false, 'type':'layer'},
           { 'label':'Sirens', 'id':'sirens-salem', 'state': false, 'type':'layer'},
           { 'label':'Congregate Care', 'id':'congregate-care', 'state': false, 'type':'layer'},
           { 'label':'Bus Routes', 'id':'bus-routes', 'state': false, 'type':'group', 'members':['bus-routes-lines', 'bus-routes-arrows']}
       ],
       oem_init: false,
       init_911: false,
       user:null,
       utoken:null,
       btoken:null,
       mcode:34033,
       ccode:"34033",
       events:[],
       incidents:[],
       userlist:[],
       dbdocs:[],
       dblinks:[],
       iicepInterval: '',
       edbInterval: '',
       _911Interval: '',
       versionLabels: {'ACTIVE':'', 'DEMO': 'Practice&Exercise'},
       currentVersionLabel: '',
       backendUrl: 'https://iicep.com/'
   }
}