<script>
import {deleteShelter} from "@/services.js";

export  default {
  methods: {
    deleteCenter: function() {
      deleteShelter(this.cid);
      this.closeWin();
    },
    closeWin: function() {
      this.dialog=false;
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  props : {
    cid: Number,
    cname: String
  }
}
</script>
<template>
      <v-dialog
          v-model="dialog"
          persistent
      >
        <template v-slot:activator="{ props }">
          <v-btn
              icon = "mdi-delete-outline"
              density="compact"
              v-bind="props"
              variant="flat"
          > </v-btn>
        </template>
        <v-card
            width="30vw"
            height="auto"
            id="deletionDialogText" >
          <v-card-text
              class="text-subtitle-1 text-center"
          >
            Do you want to delete
            <span class="font-weight-bold">{{ this.cname}}</span>?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                id="disagreeButton"
                color="red-darken-4"
                variant="outlined"
                density="default"
                @click="deleteCenter"
            >
              Yes
            </v-btn>
            <v-btn
                id="agreeButton"
                color="green-darken-4"
                variant="outlined"
                density="default"
                @click="closeWin"
            >
              No
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<style>
</style>