<script>
import Dutylog from "./eventDbDutylog.vue";
export default {
  name: "documents",
  components: {Dutylog},
  data:function() {
    return {
      showdutylog:false,
      icsroster:{},
      dutylog:[]
    }
  },
  methods: {
    openALog : function (uid){
      let that=this;
      $.ajax({
        type: "GET", url: "/getUserLog.php", dataType: 'json', data: {uid:uid},
        error: function (xhr, error) {
        },
        success: function (json) {
          console.log(json);
          if (json.Status === 'OK') {
            that.icsroster=json.icsroster;
            that.dutylog=json.icsdutylog;
            that.showdutylog=true;
          }
        }
      });
    },
    closeALog : function () {
      this.showdutylog=false;
    }
  },
  computed: {
    docData() {
      return this.$store.state._edb_evRevisionList.filter(object => object.fid>0);
    }
  }
}
</script>
<template>
    <div class="notes">
        <h3>Documents</h3>
        <div class="doc-list">
            <table class="tab">
                <tbody>
                <tr>
                    <th>Rev</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Message / Document</th>
                </tr>
                <tr v-for="entry in docData">
                    <td class="td2">
                        <span>{{entry.revId}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.sitreptime}}</span>
                    </td>
                    <td class="td2">
                        <span><a href='#' @click="openALog(entry.userid)">{{entry.user}}</a></span>
                    </td>
                    <td class="td2">
                        <span><a v-bind:href="'./getFile.php?id='+entry.fid" target="_blank">{{entry.icsfile}}</a></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
<!--        <div>-->
<!--            <vue-modaltor :visible="showdutylog" @hide="closeALog" :bg-overlay="'transparent'" :bg-panel = "'#cccccc'" :default-width="'950px'" >-->
<!--                <div>-->
<!--                    <dutylog-->
<!--                        :userics="icsroster"-->
<!--                        :actlog="dutylog">-->
<!--                    </dutylog>-->
<!--                </div>-->
<!--            </vue-modaltor>-->
<!--        </div>-->
    </div>
</template>
<style scoped>
.tab {
    width : 100%;
}
.td2 {
    font-size: 14px;
    padding : 5px 5px 5px 15px;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.notes {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.notes h3 {
    height: 25px;
}
tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}
th {
    clear: both;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    background-color: #7c93c0;
    font-weight: bold;
    padding: 0 5px 0 5px ;
}
</style>
