<template>
  <div class="status">
    <h3>Status</h3>
    <div class="status-list">
      <table>
        <tbody>
        <tr v-for="entry in statusData">
          <td class="td1">
            <button class="imgbutton" @click="setEdit(entry.id)"><img alt="edit" src="/assets/modify12.png"/></button>
            <button class="imgbutton" @click="confirmDeleteStatus(entry.id, entry.status)"><img
                alt="edit" src="/assets/delete12.png"/></button>
          </td>
          <td class="td3">
            <div :class="entry.objtype">&nbsp;</div>
          </td>
          <td class="td2">
            <span>{{ entry.status }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <ConfirmDialogue ref="confirmStatus"/>
    </div>
    <div class="status-edit">
      <input v-model="newStatus.id" type="hidden"/>
      <select v-model="newStatus.objtype">
        <option disabled value="">Select Type</option>
        <option value="equipment">Equipment</option>
        <option value="hydrant">Hydrant</option>
        <option value="personnel">Personnel</option>
        <option value="alarm">Alarm Systems</option>
      </select>
      <input v-model="newStatus.status" placeholder="Status text" type="text"/>
      <button @click="addStatus()">Save</button>
      <button @click="reset()">Clear</button>
    </div>
  </div>
</template>

<script>
import {save911, objectToFormData} from "@/services.js"
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

export default {
  name: "status911",
  components: {ConfirmDialogue},
  computed: {
    statusData() {
      return this.$store.state._911_statusData;
    }
  },
  data() {
    return {
      newStatus: {
        id: -1,
        objtype: '',
        status: ''
      }
    }
  },
  methods: {
    async confirmDeleteStatus(statid, statustext) {
      let self = this;
      try {
        const result = await this.$refs.confirmStatus.open({
          title: 'Confirm Status Deletion',
          message: `Status <strong>${statustext}</strong> will be deleted`
        });
        if (result) {
          let xdata = {mode: 'dels', id: statid};
          await save911(objectToFormData(xdata))
              .then(response => {
                if (response.Status === 'OK') {
                  this.$store.commit('delete_911_status', statid);
                }
              });
        }
      } catch (err) {
      }
    },
    reset: function () {
      this.newStatus.id = -1;
      this.newStatus.objtype = '';
      this.newStatus.status = "";
    },
    setEdit: function (keyid) {
      const idx = this.statusData.findIndex(status => status.id === keyid);
      if (idx === -1) alert("Problem");
      else {
        this.newStatus.id = this.statusData[idx].id;
        this.newStatus.objtype = this.statusData[idx].objtype;
        this.newStatus.status = this.statusData[idx].status;
      }
    },
    async addStatus() {
      if (this.newStatus.status === '') {
        alert('Status cannot be empty');
      } else {
        let xdata = {
          mode: "saves",
          id: this.newStatus.id,
          statustext: this.newStatus.status,
          objtype: this.newStatus.objtype
        };
        await save911(objectToFormData(xdata))
            .then(response => {
              if (response.Status === 'OK') {
                this.$store.commit('update_911_status', {
                  id: parseInt(response.id),
                  status: this.newStatus.status,
                  objtype: this.newStatus.objtype
                });
                this.newStatus.id = -1;
                this.newStatus.status = '';
                this.newStatus.objtype = '';
              }
            });
      }
    }
  }
}
</script>

<style scoped>
.td1 {
  padding-left: 10px;
  width: 60px;
  box-sizing: border-box;
}

.td2 {
  font-size: 16px;
  padding: 5px 5px 5px 15px;
  font-style: italic;
  font-weight: bold !important;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.td3 {
  font-size: 16px;
  padding: 5px 5px 5px 15px;
  font-style: normal;
  font-weight: bold;
  text-align: left;
}

.status {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: 'Calibri', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

.status h3 {
  height: 25px;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.status-list {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.status-edit {
  border: 1px solid #fe0000;
  width: 100%;
  height: 35px;
}

.status-edit select {
  width: 15%;
  display: inline-block;
  float: left;
  margin: 5px 0 5px 5px;
  height: 23px;
  border: 1px solid #fe0000;
}

.status-edit input {
  width: 50%;
  display: inline-block;
  float: left;
  border: 1px solid #fe0000;
  margin: 5px 0 5px 5px;
  height: 23px;
}

.status-edit button {
  width: 41px;
  display: inline-block;
  float: right;
  margin: 5px 5px 5px 0;
  height: 23px;
  background-color: grey;
  padding: 0;
  outline: none;
}

.imgbutton {
  padding: 2px;
  line-height: 12px;
  border: 2px solid black;
}

.hydrant {
  display: inline-block;
  background-image: url('/assets/hydrant30.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

.equipment {
  display: inline-block;
  background-image: url('/assets/equipment30.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

.personnel {
  display: inline-block;
  background-image: url('/assets/personnel30.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

.alarm {
  display: inline-block;
  background-image: url('/assets/alarm30.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}
</style>
