<script setup>
import mapnodraw from "@/components/mmapnodraw.vue";
import "mapbox-gl/dist/mapbox-gl.css"
</script>
<template>
  <v-layout class="c100">
    <div id="layout">
      <mapnodraw/>
    </div>
  </v-layout>
</template>
<script>
export default {
  components: {
    mapnodraw
  },
  name: 'quadmapcomp',
  props: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
  }
}
</script>

<style scoped>
#layout {
  flex: 1;
  display: flex;
  position: relative;
  width: 100vw;
  /*height: calc(100vh - 64px - 56px);*/
  margin-bottom: 0;
}
.c100 {
  height: 100%;
}
</style>