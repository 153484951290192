<script>
import deleteUser from "@/components/deleteUser.vue";
import editUser from "@/components/editUser.vue";
export  default {
  components : {deleteUser, editUser},
  mounted() {
  },
  created() {
  },
  methods: {
    closeWin: function () {
      this.dialog=false;
      // this.$router.push({name: "home"})
    },
  },
  data: () => ({
      dialog: false,
      newid: -2,
      editDialog: false,
      delDialog: false,
  }),
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '80vw';
      } else if (this.$vuetify.display.name==='md') {
        return '60vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '50vw';
      } else {
        return '50vw';
      }
    },
    users() {
      let ul = this.$store.state.users;
      let ulist = [];
      ul.forEach( u => {
        ulist.push({
          userid: u.userid,
          Roles: u.Roles,
          uroles: u.uroles,
          email: u.email,
          FirstName: u.FirstName,
          LastName: u.LastName,
          phone: u.phone,
          mobile: u.mobile,
          permissions: u.permissions,
          position: u.Roles,
          userName: u.FirstName + ' ' + u.LastName
        })
      });
      return ulist;
    }
  }
}
</script>
<template>
  <v-dialog
      theme="light"
      v-model="dialog"
      persistent
      :width="dlgwid"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          v-if="['B','C','G'].includes($store.state._main_accessLevel)"
          size="small"
          class="text-right"
          v-bind="props"
          variant="outlined"
      > User Administration
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-center"> User administration </v-card-title>
      <v-table
          fixed-header
          height="64vh"
          density="default"
      >
        <thead>
        <tr >
          <th class="text-center pa-0">
            Name
          </th>
          <th class="text-center pa-0">
            Roles
          </th>
          <th class="text-right"></th>
          <th class="text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in users"
            :key="item.userid"
        >
        <td class="text-center pa-0">{{ item.FirstName }}&nbsp;{{ item.LastName }}</td>
        <td class="text-left pa-0">{{ item.position }}</td>
        <td class="text-right">
            <editUser :uid="item.userid"/>
        </td>
        <td class="text-right">
            <deleteUser :uid="item.userid" :uname="item.userName" :umail="item.email"/>
        </td>
        </tr>
        </tbody>
      </v-table>
      <v-card-actions class="d-flex justify-space-between">
            <v-btn
                class="ml-4"
                id="homePageButton"
                variant="outlined"
                @click = "closeWin"
            > Close
            </v-btn>
            <editUser :uid="newid" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>