<script setup>
</script>

<template>
  <v-app class="rounded rounded-md noextension">
    <v-app-bar v-if="showHeader">
      <v-app-bar-title>IICEP <span class="text-red">{{vlabel}}</span>&nbsp;</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {db, auth} from '@/firebase/init.js';
import iicepStore from "@/iicepStore.js";

export default {
  created() {
  },
  mounted() {
    //this.$store.dispatch('fetchUsers');
  },
  methods: {
  },
  computed: {
    showHeader() {
      return this.$route.meta.hdr || false
    },
    vlabel() {
      return this.$store.state._main_versionLabel ;
    }
  },
  data: () => ({
    email:'',
    password:'',
    search: '',
    panel: '',
    unchangedData: true,
    editedIndex: -1,
    confirmDelete: true,
    drawer: null,
  }),
}
</script>

<style scoped>
.noextension {
  max-height: 100vh;
}
</style>
