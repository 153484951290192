export const defaultWorkerItem = {
        worker_id: 0,
        phone: 'xxxyyyzzzz',
        name: 'New Worker',
        agency: 'New-Worker-Agency',
        sum366: 0,
        today: 0,
        daily: {
            values: [],
            date: ''
        },
        readings: []
    };
export const defaultEmWorkerItem = {
        worker_id: 0,
        phone: 'xxxyyyzzzz',
        name: 'New Worker',
        agency: 'Worker Agency',
        missions: [
            {
                name: '',
                completed: false,
                tasks: [
                    {
                        descript: '',
                        location: {},
                        time: 0
                    }
                ]
            }
        ]
    };
export const geoJsonHeader = {
    type: 'FeatureCollection',
    features: []
};