<script>
import newResourceRequest from "@/components/addNewResReq.vue"
import {mapGetters} from 'vuex';

export default {
  components: {newResourceRequest},
  name: 'resrequests',
  data: () => ({
    layoutInline: true,
    currentReq: null,
    expanded: [],
    editing: false,
    modified: false,
    editMode: {}, // Array to track the edit mode of each panel
    formData: {},// Array to store form data for each panel
    readOnly: {},
    needLabels: ['Operators Needed', 'Skills Needed', 'Supplies Needed', 'Other Needs'],
    editingID: null,
    openID: null,
    collapsed: false,
  }),
  methods: {
    toggleEditMode(rkey) {
      if (!this.editingID) {
        this.editMode[rkey] = !this.editMode[rkey];
        this.readOnly[rkey] = !this.readOnly[rkey];
        if (this.editMode[rkey]) {
          this.editingID = rkey;
        }
      }
    },
    saveForm(rkey) {
      if (this.$refs['form' + rkey][0].validate()) {
        this.editingID = null;
        this.editMode[rkey] = false;
        this.readOnly[rkey] = true;
      }
    },
    cancelForm(rkey) {
      this.editingID = null;
      this.items.forEach(itm => {
        if (itm['id'].toString() === rkey) {
          this.formData[rkey] = {...itm}; // Reset to original item data
        }
      })
      this.$refs['form' + rkey][0].reset();
      this.editMode[rkey] = false;
      this.readOnly[rkey] = true;
    },
    goToNewRR: function() {
      let self = this;
      self.$router.push("/oem/new-resource-request");
    }
  },
  watch: {
    items: {
      handler(newItems) {
        let newReadOnly = {};
        let newFormData = {};
        if (this.editingID) {
          console.log('stash ', this.editingID);
          newReadOnly[this.editingID] = false;
          newFormData[this.editingID] = this.formData[this.editingID];
        }
        newItems.forEach(newitem => {
          if (this.editingID !== newitem['id']) {
            newFormData[newitem['id']] = {...newitem};
            newReadOnly[newitem['id']] = true;
          }
        })
        this.formData = newFormData;
        this.readOnly = newReadOnly;
        console.log("items watcher:", newItems.length, Object.keys(this.formData).length);
      },
      immediate: true // Trigger the watcher immediately when the component is created
    }
  },
  computed: {
    ...mapGetters(['getOwnRRs', 'getEvents']),
    items() {
      console.log("items computed")
      return this.getOwnRRs;
    },
    eventList() {
      let elist = this.eventItems;
      let rrItem = {};
      if (this.openID) {
        rrItem = this.items.find(rri => rri["id"].toString() === this.openID);
        elist = this.eventItems.find(event => event.id === rrItem['relevent'])
            ? this.eventItems : [{'v': rrItem['relevent'], 'l': '', 'a': true}, ...this.eventItems]
      }
      return elist;
    },
    eventItems() {
      return this.getEvents.map(ev => ({'l': ev.ename, 'v': ev.id, 'a': false}));
    },
  },
  mounted() {
  },
  created() {
    this.editMode = Array(this.items.length).fill(false);
    this.readOnly = Array(this.items.length).fill(true);
    this.formData = {};
  }
}
</script>
<template>
  <v-card
      id="rescard"
      color="white"
      variant="flat"
  >
    <v-card-title class="d-flex ">
      Resource Requests
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="ma-4 mb-2">
          <v-btn
            density="default"
            variant="outlined"
            @click="goToNewRR"
          >ADD NEW RESOURCE REQUEST
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels
              v-model="openID"
              density="compact"
              variant="accordion"
          >
            <v-expansion-panel
                v-for="(rvalue, rkey) in formData"
                :key="rkey"
                :value="rkey"
            >
              <v-expansion-panel-title>
                Resource request {{ rvalue['idx'] }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form :ref="'form' + rkey">
                  <v-row class="ma-0">
                    <v-col class="pa-1 pt-0" cols="12" md="6">
                      <span class="text-body-3 font-italic">Submitted by:</span>
                      <span class="text-body-3 font-weight-bold">{{ rvalue['usr'] }}</span>
                    </v-col>
                    <v-col class="pa-1 pt-0" cols="12" md="6">
                      <span class="text-body-3 font-italic">Jurisdiction:</span>
                      <span class="text-body-3 font-weight-bold">{{ rvalue['jname'] }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-1" cols="12" md="6">
                      <span class="text-body-3 font-italic">Delivery logistics:</span>
                      <span class="text-body-3 font-weight-bold">{{ rvalue['log_addr'] }}</span>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="6">
                      <span class="text-body-3 font-italic">Last update:</span>
                      <span class="text-body-3 font-weight-bold">{{ rvalue['lastupd'] }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0 pb-1" cols="12">
                          <v-autocomplete
                              v-model="rvalue['rneedv']"
                              :items="$rrNeedTypes"
                              :readonly="readOnly[rkey]"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="false"
                              item-title="l"
                              item-value="v"
                              label="Need Type"
                              variant="outlined"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col class="pa-0 pt-1 pb-1" cols="12">
                          <v-autocomplete
                              v-model="rvalue.severity"
                              :items="$rrSeverity"
                              :readonly="readOnly[rkey]"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="false"
                              item-title="l"
                              item-value="v"
                              label="Severity level"
                              variant="outlined"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col class="pa-0 pt-1" cols="12">
                          <v-textarea
                              v-model="rvalue['rneedescr']"
                              :readonly="readOnly[rkey]"
                              density="compact"
                              hide-details="true"
                              label="Need description"
                              no-resize
                              persistent-hint
                              rows="2"
                              variant="outlined"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="6">
                      <v-textarea
                          v-model="rvalue['sitbrief']"
                          :readonly="readOnly[rkey]"
                          density="compact"
                          hide-details="true"
                          label="Situation brief"
                          no-resize
                          persistent-hint
                          rows="6"
                          variant="outlined"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-text-field
                              v-model="rvalue.from"
                              :readonly="readOnly[rkey]"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="true"
                              label="From date"
                              variant="outlined"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-text-field
                              v-model="rvalue.to"
                              :readonly="readOnly[rkey]"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="true"
                              label="Return date"
                              variant="outlined"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-textarea
                              v-model="rvalue['need1']"
                              :label="needLabels[rvalue['rneedv']]"
                              :readonly="readOnly[rkey]"
                              density="compact"
                              hide-details="true"
                              no-resize
                              persistent-hint
                              rows="4"
                              variant="outlined"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-textarea
                              v-model="rvalue['need2']"
                              :readonly="readOnly[rkey]"
                              density="compact"
                              hide-details="true"
                              label="Additional needs"
                              no-resize
                              persistent-hint
                              rows="4"
                              variant="outlined"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-autocomplete
                              v-model="rvalue.severity"
                              :items="severityItems"
                              :readonly="readOnly[rkey]"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="false"
                              item-title="l"
                              item-value="v"
                              label="Severity level"
                              variant="outlined"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="6">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="12">
                          <v-autocomplete
                              v-model="rvalue['relevent']"
                              :items="eventList"
                              class="ma-0 pa-0 smallsize"
                              density="compact"
                              hide-details="false"
                              item-title="l"
                              item-value="v"
                              label="Related event"
                              readonly
                              variant="outlined"
                          >
                            <template v-slot:item="{ on, item }">
                              <v-list-item :disabled="item.a" v-on="on">{{ item.l }}</v-list-item>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col cols="12">
                    <v-btn v-if="!editMode[rkey]" class="ma-1" color="primary" @click="toggleEditMode(rkey)">Edit
                    </v-btn>
                    <v-btn v-if="editMode[rkey]" class="ma-1" color="primary" @click="saveForm(rkey)">Save</v-btn>
                    <v-btn v-if="editMode[rkey]" class="ma-1" color="warning" @click="cancelForm(rkey)">Cancel</v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="rvalue['responses'].length>0">
                  <v-col cols="12">
                    <v-card class="ma-1" variant="outlined">
                      <v-card-text class="sct">
                        <template v-for="(resp) in rvalue['responses']">
                          <span class="font-weight-bold">{{resp['usr']}}({{resp['umail']}})</span>,
                          <span class="font-italic">{{resp['muniname']}}</span>: {{resp['rtext']}}<br/>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-card-actions>
                </v-card-actions>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.sct {
  height: 120px;
  overflow-y: auto;
}

.text-body-3 {
  font-size: 13px;
}

::v-deep(.v-textarea) {
  line-height: 1.55;
}

::v-deep(.v-field__input) {
  --v-field-input-padding-top: 3px;
  --v-field-input-padding-bottom: 3px;
  font-size: 13px;
}

::v-deep(.v-field .v-input .v-label) {
  font-size: 13px;
}

::v-deep(.v-input--density-compact) {
  --v-input-control-height: 34px;
  --v-input-padding-top: 3px;
}
</style>
<style lang="scss" scoped>
$autocomplete-line-height: 1.1;
</style>