<script>
import mapboxgl from "mapbox-gl";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ'
export default {
  name: 'mapwrk',
  data: () => ({
    wmap: null,
    wmapmode: 'sat_map',
    resizeObserver: null,
    maplayers: [],
    dw_pins: [],
    ew_pins: [],
    current_map: 'sat_map',
    sat_map: [
      'satellite'
    ],
    str_map: [
      'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
      'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
      'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
      'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
    ],
    sourcedefs: {
      dw_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      ew_ftrs_src: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
    },
    layerdefs: [
      {
        id: 'dw_points',
        type: 'circle',
        source: 'dw_ftrs_src',
        paint: {
          'circle-color': '#111111',
          'circle-opacity': 0.9,
          'circle-radius': 4,
        },
        layout: {
          'visibility': 'visible',
        }
      }, {
        id: 'ew_points',
        type: 'circle',
        source: 'ew_ftrs_src',
        paint: {
          'circle-color': '#111111',
          'circle-opacity': 0.9,
          'circle-radius': 4,
        },
        layout: {
          'visibility': 'visible',
        }
      }
    ]
  }),
  computed: {},
  watch: {
    wmapmode(newmode) {
      if (newmode === 'str_map') {
        this.setStreetMap();
      }
      if (newmode === 'sat_map') {
        this.setSatelliteMap();
      }
    },
    dw_pins(newdata) {
      this.wmap.getSource('dw_ftrs_src').setData(newdata);
    },
    ew_pins(newdata) {
      this.wmap.getSource('ew_ftrs_src').setData(newdata);
    },
  },
  mounted() {
    this.wmap = new mapboxgl.Map({
      container: this.$refs.wMapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.wmap.on('load', () => {
      Object.keys(this.sourcedefs).forEach(k => {
        this.wmap.addSource(k, this.sourcedefs[k]);
      });
      this.layerdefs.forEach(lr => {
        this.wmap.addLayer(lr);
      })
    });
    this.resizeObserver = new ResizeObserver(() => {
      this.wmap.resize();
    });
    this.resizeObserver.observe(this.$refs.wMapContainer);
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.wmap) {
      this.wmap.remove();
    }
  },
  methods: {
    setStreetMap() {
      this.sat_map.forEach(l => {
        this.wmap.setLayoutProperty(l, 'visibility', 'none');
      })
      this.str_map.forEach(l => {
        this.wmap.setLayoutProperty(l, 'visibility', 'visible');
      })
    },
    setSatelliteMap() {
      this.sat_map.forEach(l => {
        this.wmap.setLayoutProperty(l, 'visibility', 'visible');
      })
      this.str_map.forEach(l => {
        this.wmap.setLayoutProperty(l, 'visibility', 'none');
      })
    },
    handleWindowResize() {
      this.wmap.resize();
    },
  }
}
</script>

<template>
  <div ref="wMapContainer" class="map-container"></div>
</template>

<style scoped>
.map-container {
  flex: 1;
}
</style>